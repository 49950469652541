<template>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-2 ShortcutItem" v-if="inEditor || (showRoles && showRoles.includes(userInfo.title))">
        <a :href="docSectionParameter ? pageLink+'?docSection='+docSectionParameter : pageLink" class="shortcut-items">
            <div class="shortcut-icon-panel">
                <img v-bind:src="`${dam_hostname}${image['@link']}`" 
                alt="documents" :onerror="`this.src='${cdnRoot}/catalogo/recursos/imagen-no-disponible.png`">
            </div>
            <div class="shortcut-panel"
            v-bind:style="getBackgroundImage(damLink ? damLink['@link'] : null)">
                <span>{{ text }}</span>
            </div>
         <!--{{ icon }}-->
        </a>
    </div>
</template>

<script>
import { EditorContextHelper } from '@magnolia/vue-editor';
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: "ShortcutItem",
  mixins: [BaseComponent],  
  props: ["text", "image", "damLink", "pageLink", "docSectionParameter", "showRoles"],
  data() {
    return {
      dam_hostname: process.env.VUE_APP_MGNL_DAM_RAW,
    }
  },
 computed: {
    staticRoot(){
        return process.env.VUE_APP_MGNL_STATIC;
    },

    userInfo() {
        return this.$store.state.nitida.userInfo;
    },
    inEditor() {
        return (EditorContextHelper.inIframe() || EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview());
    }       
 },
 methods: {
    getBackgroundImage(damLink) {
        if (damLink === null || damLink === 'undefined' || damLink.includes('undefined')) {
            return 'background: linear-gradient(rgba(255, 255, 255, 0.1) 0, #009CBD 100%)';
        }
      return `background: linear-gradient(rgba(255, 255, 255, 0.1) 0, #009CBD 100%), url(${this.dam_hostname}${damLink})`;
    }
 }  
};
</script>

<style>

</style>