<template>
  <div class="Basic">
    <!--<div class="hint">[Basic Page]</div>
    <h1>{{ title || metadata['@name'] }}</h1>-->

    <main id="main-wrapper">
      <!--<div class="hint">[Main Area]</div>-->
      <EditableArea v-if="main" v-bind:content="main"
      style="width: 100%;"/>
    </main>
  </div>
</template>

<script>
import { EditableArea } from '@magnolia/vue-editor';

export default {
  name: "Basic",
  components: {
    EditableArea
  },
  props: ["title", "metadata", "main"]
};
</script>
