<template>
  <nav v-if="pagenav">
    <router-link
		:to="item['@path']"
		v-for="item in pagenav" :key="item['@id']"
        :class="isActive(item) ? 'active' : ''">
			<i :class="item.icon"></i>
			{{ item.title }}
            <div v-if="item.notifications>0 && userInfo.title!='DIRECCION'" class="new-doc-list-bubble bubble-menu">{{item.notifications}}</div>
		</router-link>
  </nav>
</template>

<script>
import {
  getLanguages,
  getCurrentLanguage,
  changeLanguage
} from "../helpers/AppHelpers";

export default {
  name: "Navigation",
  data() {
    return {
      pagenav: [],
      languages: getLanguages(),
      currentLanguage: getCurrentLanguage()
    };
  },

  methods: {
    isActive(item) {
        var currentPath = this.$router.currentRoute.value.path;
        if (currentPath && (currentPath === item['@path'] || (item['@name'] != '/' && currentPath.indexOf(item['@name']) != -1))) {
            return true;
        }
        return false;
    },		
    goTo(item) {
        console.log(`*** goto: [ path: '${item['@path']}' ]`);
        this.$router.push({ path: item['@path'] });
    },   
    updateNotifications() {
			if (this.$store.state.nitida.notifications && this.pagenav) {
					let allNotifications = this.$store.state.nitida.notifications;
					this.pagenav.forEach(page => {      
					if (page['notificationPath']) {
						page.notifications=this.getPathNotifications(allNotifications, page['notificationPath']);
					}
					else {
						page.notifications=0;
					}
				});
			}
	},
	getPathNotifications(allNotifications, path) {
		if (this.$store.state.nitida.userInfo.clientCode) {
			path = path.replace("{clientCode}",this.$store.state.nitida.userInfo.clientCode);
		}
		let notifications = 0;
		for (let ncat of allNotifications) {
			for (let pathPart of path.split(',')) {
				if (ncat.displayPath.indexOf(pathPart.trim())==0) {
					notifications += ncat.items.length;
				}
			}
		}
		return notifications;
	},
    async navigation(userTitle) {
        let url = `${process.env.VUE_APP_MGNL_API_NAV}${process.env.VUE_APP_MGNL_SITE_PATH}`;
        console.log(`Get Nav Con : ${url}`);
        const pagenavResponse = await fetch(url);
        const pagenav = await pagenavResponse.json();
            const newPagenav = [
        {
            "@id": pagenav["@id"],
            "@name": "/",
            "@path": process.env.VUE_APP_MGNL_SITE_PATH,
            title: pagenav.title,
            icon: pagenav.icon,
            showRoles: pagenav.showRoles
        }
        ];
            pagenav["@nodes"].forEach(node => {
                const page = pagenav[node];        
                if (page['showNav'] === 'true' && (!page['showRoles'] || page['showRoles'].includes(userTitle))) {
                    newPagenav.push(page);
                }
            });
            this.pagenav = [...newPagenav];
            /*this.pagenav.forEach(node => {
                console.log(`*** page: ${JSON.stringify(node)}`);
            });*/

			this.updateNotifications();
        /*this.pagenav = newPagenav.concat(
        pagenav["@nodes"].map(nodeName => pagenav[nodeName])
        );*/        
    }       
  },

  computed: {
      userInfo() {
          return this.$store.state.nitida.userInfo;
      }
  },  

  watch: {
    '$store.state.nitida.userInfo': function() {
        this.navigation(this.$store.state.nitida.userInfo.title);
    },
	'$store.state.nitida.notifications': function() {
		   this.updateNotifications();
		}
  }

};
</script>
