<template>
   <div id="carouselExampleIndicators" class="carousel slide col-md-6 col-lg-6" data-bs-ride="carousel"
   style="padding: 0 10px;" v-if="inEditor || (showRoles && showRoles.includes(userInfo.title))">
        <div class="carousel-indicators">
            <button type="button" 
                v-bind:class="[(index === 0 ? 'active' : '')]" v-for="(item, index) in news" :key="item['@id']"
                data-bs-target="#carouselExampleIndicators" 
                :data-bs-slide-to="index"  
                aria-current="true" :aria-label="'Slide '+index"
            >
            </button>
        </div>
        <div class="carousel-inner">
            <div v-bind:class="['carousel-item', (index === 0 ? 'active' : '')]" 
                 v-for="(item, index) in news" :key="item['@id']"
            >
                <a href="#newsModal" data-bs-toggle="modal" @click="currentItem = item">
                    <div class="fill" :style="getBackgroundImage(item)">
                </div>
                </a>               
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="fa-solid fa-chevron-left fa-xl" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="fa-solid fa-chevron-right fa-xl" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
      <NewsDetail :item="currentItem" />        
    </div>
</template>

<script>
import { EditorContextHelper } from '@magnolia/vue-editor';
import BaseComponent from "./common/BaseComponent.js";
import NewsDetail from "./NewsDetail";

export default {
  name: "FeaturedNewsCarousel",
    props: ["showRoles"],
	mixins: [BaseComponent],
  components: {
    NewsDetail
  },   
  data() {
    return {
      currentItem: null,     
 	    dam_hostname: process.env.VUE_APP_MGNL_DAM_RAW,
    }
  },
	computed: {
        userInfo() {
            return this.$store.state.nitida.userInfo;
        },  
		inEditor() {
			return (EditorContextHelper.inIframe() || EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview());
		},             
		news() {
			return this.$store.state.nitida.news.filter(x => x['hightlighted'] === 'true');
		}
	},
  methods: {
  },
}
</script>

<style>
.carousel,.item,.active{
    height:100%;
}
.carousel-inner{
    height:100%;
}

</style>

