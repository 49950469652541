<template id="app">
  <div>
    <header>
        <nav class="navbar fixed-top navbar-expand-lg navbar-light d-none d-md-block">
          <div class="container-fluid"
          style="height: 68px;">
              <a id="sidebar-button" @click="hideSideBar"> 
                  <i class="fa-solid fa-bars fa-lg"></i>
              </a>
              <a href="/intranet" class="navbar-brand">
                 <img :src="`${staticRoot}/assets/nitida-logo.svg`" alt="Nitida" width="120">
              </a>
              <div class="collapse navbar-collapse" id="navbarText">
                <div class="navbar-nav me-auto mb-2 mb-lg-0"></div>
                    <ul>
                        <li class="nav-item dropdown">
                            <div style="display: flex; flex-direction: column; align-items: flex-end; cursor: pointer;" data-bs-toggle="dropdown">
                              <span style="font-size: 0.875rem; font-weight: 500;">{{ userProfile ? userProfile.email : '' }}</span>
                              <span v-if="!currentClient" style="font-size: 0.85rem;">
																{{ userInfo.clientName?userInfo.clientName:'' }}
															</span>
                              <span v-if="currentClient" style="font-size: 0.85rem;">
																{{ currentClient.clientName }}
															</span>
                            </div>
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li v-for="client in clients" :key="client.clientId">
																	<a :class="'dropdown-item' + (currentClient && currentClient.clientId == client.clientId ? ' active' : '')" @click="changeClient(client)">
																		{{ client.clientName }}
																	</a>
																</li>
                                <li>
																	<a :class="'dropdown-item' + (clients.length > 0 ? ' logout' : '')" @click="logout">{{ $t('app.logout') }}</a>
																</li>
                            </ul>
                        </li>
                    </ul>
                </div>
           </div>
        </nav>
        <!-- Phone Nav -->
        <nav class="navbar fixed-top d-md-none navbar-phone"
        style="background-image: url(/assets/corner-3.png);" id="mainTop">
          <div class="container-fluid p-0">
              <a class="navbar-brand">
                 <a v-if="!showNavigation" href="/intranet">
                    <img v-if="!showNavigation" class="mb-2" :src="staticRoot+'/assets/nitida-logo.svg'" alt="Nitida" width="120">
                 </a>
                 <div v-if="showNavigation" class="navigation-header">
                 <i class="fa-solid fa-arrow-left me-2" @click="exitDetail()"></i>
                 <span>{{ detailTitle }}</span>
                 </div>
              </a>
              <div v-if="!showNavigation" class="" id="">
                <div class="navbar-nav me-auto mb-2 mb-lg-0"></div>
                    <ul>
                        <li class="nav-item dropdown">
                            <a style="display: flex; flex-direction: column; align-items: flex-end;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <span style="font-size: 0.875rem; font-weight: 500;overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 150px;">{{userProfile ? userProfile.email : ''}}</span>
                              <span v-if="!currentClient" style="font-size: 0.85rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 150px;">
                               	{{ userInfo.clientCode ? userInfo.clientName : '' }}</span>
                              <span v-if="currentClient" style="font-size: 0.85rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 150px;">
                               	{{ currentClient.clientName }}</span>
                              </a>
														
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </a>
														<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
																<li v-for="client in clients" :key="client.clientId">
																	<a :class="'dropdown-item' + (currentClient && currentClient.clientId == client.clientId ? ' active' : '')" @click="changeClient(client)">
																		{{ client.clientName }}
																	</a>
																</li>
                                <li>
																	<a :class="'dropdown-item' + (clients.length > 0 ? ' logout' : '')" @click="logout">{{ $t('app.logout') }}</a>
																</li>
                            </ul>
                        </li>
                    </ul>
                </div>
          </div>
        </nav>

     
    </header>
    <div class="container-fluid main-container">
      <div id="sidebar-wrapper" class="sidebar">
          <Navigation />
      </div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <!-- Bottom Menu -->
    <footer class="d-md-none">
        <NavigationPhone v-if="!showNavigation" />
    </footer>
  </div>
</template>

<script>
import api from "./api/nitida.js";
import Navigation from "./components/Navigation";
import NavigationPhone from "./components/NavigationPhone";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";

export default {
  name: "app",
  components: {
    Navigation, NavigationPhone
  },
	data() {
    return {
			showNavigation: false,
			detailTitle: '',
    };
  },
	mounted() {
		api.getAreas();
		api.getNews();
	},
  computed: {
    staticRoot() {
      return process.env.VUE_APP_MGNL_STATIC;
    },
		userProfile() {
			return this.$store.state.nitida.profile;
		},
		userInfo() {
			if (!this.$store.state.nitida.incidences 
			&& this.$store.state.nitida.userInfo 
			&& this.$store.state.nitida.userInfo.clientCode
			&& this.$store.state.nitida.currentClient == null) {
				api.getIncidences();
				api.getDocumentConfig();
				api.getNotifications();
				if (window.evas_existsLoadingContainer()) {
					window.evas_removeLoadingContainer();
				}
			}
			return this.$store.state.nitida.userInfo;
		},
		clients() {
			return this.$store.state.nitida.clients;
		},
		currentClient() {
			return this.$store.state.nitida.currentClient;
		}
  },
  watch: {
		userProfile: function() {
			api.getUserInfo();
		},
		'$store.state.nitida.detailNavigation': function() {
			this.detailTitle = this.$store.state.nitida.detailNavigation.title;
			this.showNavigation = this.$store.state.nitida.detailNavigation.show;
		},
		'$store.state.nitida.currentClient': function() {			
			api.getIncidences();
			api.getDocumentConfig();
			api.getNotifications();
			if (window.evas_existsLoadingContainer()) {
				window.evas_removeLoadingContainer();
			}
		}
	},
  methods: {
		exitDetail() {
			this.$store.commit('nitida/setDetailNavigation', { title: '', show: false });
            //window.scrollTo(0,0);
            document.location.href="#mainTop";          
		},
    logout: function() {
      //TODO: pedir confirmación
	  	window.kc_logout();
    },
    hideSideBar: function() {
			$('#sidebar-wrapper').toggle(function() {
			});
    },
		changeClient(client) {
			/*if (this.currentClient && this.currentClient.clientId == client.clientId) {
				return;
			}*/	
			this.$store.commit('nitida/setCurrentClient', client);
		}
  }
};
</script>

<style>
@import "./App.css";
.navigation-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 320px;
}
.logout {
	border-top: 1px solid #000;
}

</style>
