import { SubfamilyType } from '../../components/common/constants';
const mobile = require('is-mobile');

export default {
	data() {
    return {
			subfamilyType: SubfamilyType,
      dam_hostname: process.env.VUE_APP_MGNL_DAM_RAW,
    }
 	},
	 computed: {
		staticRoot(){
			return process.env.VUE_APP_MGNL_STATIC;
		},
		cdnRoot(){
			return process.env.VUE_APP_STORAGE_BASE_PATH;
		},
	},
	methods: {
		formatFullDate(date) {
            var dt = null; 

            if (!date) {
                dt = new Date();
            } else {
                dt = new Date(date);
			    if (dt.getTime() < 0) return null;                 
            }
			            
            return dt.toLocaleString(this.$i18n.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
		},        
		formatDate(date) {
			var dt = null;

			if (!date) {
				dt = new Date();
			} else {
				dt = new Date(date);
				if (dt.getTime() < 0) {
					return null;
				}
			}

			return dt.toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
		},
		formatDateTime(date) {
      var dt = null;

			if (!date) {
				dt = new Date();
			} else {
				dt = new Date(date);
				if (dt.getTime() < 0) {
					return null;
				}
			}

			var iso = dt.toISOString();

			if (iso.indexOf('T00:00:00') != -1) {
				return dt.toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: '2-digit', day: '2-digit' });						
			}
							
			return dt.toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' ' 
				+ dt.toLocaleTimeString(this.$i18n.locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' });      
		},
		isMobile() {
			return mobile({ tablet: true });
		},
		getBackgroundImage(item) {
			if (!item) {
				return '';
			}
			return `background-image: url('${this.dam_hostname}${item.image.renditions ? item.image.renditions['480'].link : item.image.link}');`;
		}
	}
};
