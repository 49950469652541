<template>
    <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12">
              <!-- Search Phone -->
              <!--<div class="search-input input-group d-md-none">
                  <input v-model="searchText" type="search" class="form-control" placeholder="Buscar..." @search="filterProducts()">
                  <button type="button" class="btn btn-primary search-btn" @click="filterProducts()"><i class="fa-solid fa-magnifying-glass"></i></button>
              </div>-->
              
               
               <h1 class="businessAreaNavigation-title d-none d-md-block">{{ title }}</h1>
               <!--<h1 class="businessAreaNavigation-title d-block d-md-none mb-0">Elige tipo de producto</h1>-->
               <div class="businessAreaNavigation-container d-none d-md-block">
                    <ul class="nav nav-pills justify-content-start businessAreaNavigation-list">								 <li class="nav-item" v-for="item in areas" :key="item['@id']">
                             <a :class="`nav-link${isActive(item) ? ' active' : '' }`"
                             :style="getBackground(item)"
                             role="button" @click="goTo(item)">
							 {{ item['title'] }}
							 </a>
                         </li>     
                    </ul>
               </div>
          </div>
      </div>
    </div>
</template>

<script>
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: "BusinessAreaNavigation",
  props: ['title'],  
	mixins: [BaseComponent],
  data() {
   	return {
   	}
 	},
	computed: {
		areas() {
			return this.$store.state.nitida.areas;
		},
		currentArea() {
			return this.$store.state.nitida.currentArea;
		},
	},
 	methods: {
		goTo(item) {
			console.log(`*** goto: [ path: '${item['areaLink']}' - area: ${item['@id']} ]`);
			this.$router.push({ path: item['areaLink'], query: { area: item['@id'] } });
		},         
		isActive(item) {
			return this.currentArea && this.currentArea['@id'] === item['@id'];
		},
		getBackground(item) {
		 	if (this.isActive(item)) {
				return `background: ${item['areaColorBackground']}; border: 1px solid ${item['areaColorText']}; color: ${item['areaColorText']}`;
			}
			return `color: #5D5C5C;`;
	    }
 },
}
</script>

<style>
/*** Search Phone ***/
.search-input .form-control {
    font-size: 0.875rem;
}
.btn.btn-primary, .btn.btn-primary.search-btn {
    background-color: #009CBD;
    border: 1px solid #009CBD;
}

</style>