<template>
    <div class="container-fluid p-0 d-block d-md-flex BusinessAreaProductList" id="top2">
      <aside :class="`BusinessAreaProductList-container${currentSubfamily ? ' phone-hidden' : ''}`">
          <nav class="collapse show bd-links">
			<h1 v-if="currentArea" class="businessAreaNavigation-title d-block d-md-none mb-0" style="font-weight: 600">{{currentArea['title']}}</h1>
              <ul class="list-unstyled mb-0 py-3 pt-md-1">
                <li class="mb-1 type-product">
                  <div class="collapse show" id="lavado-manual-collapse">
                    <h3>{{ $t('products.title') }}</h3>
                    <ul class="list-unstyled fw-normal pb-1 small BusinessAreaProductList-list">
                      <li v-for="subfamily in subFamiliesByChemical" :key="subfamily['@id']">
							<a role="button" :class="`d-inline-flex align-items-center${currentSubfamily && currentSubfamily['@id'] === subfamily['@id']  ? ' active' : ''}`" @click="loadProducts(subfamily, subfamilyType.CHEMICAL_SUBFAMILY)">
							{{ subfamily['subFamilyCode']['description'] }}
							</a>
					  </li>
                    </ul>
                    <h3>{{ $t('products.title2') }}</h3>
                    <ul class="list-unstyled fw-normal pb-1 small BusinessAreaProductList-list">
                       <li v-for="subfamily in subFamiliesByEquipment" :key="subfamily['@id']">
							<a role="button" :class="`d-inline-flex align-items-center${currentSubfamily && currentSubfamily['@id'] === subfamily['@id']  ? ' active' : ''}`" @click="loadProducts(subfamily, subfamilyType.EQUIPMENT_SUBFAMILY)">
							{{ subfamily['subFamilyCode']['description'] }}
							</a>
						</li>
                    </ul>
                 </div>
                </li>
              </ul>
          </nav>
      </aside>
      <main v-if="currentSubfamily" class="main-products order-1">
		  <!--<div :class="`my-1 input-group d-block d-md-none`">
			<button type="button" class="btn btn-primary" @click="currentSubfamily = null">Volver</button>
		  </div>-->
          
          <div class="container-fluid products-container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="search-input input-group d-flex d-md-none">
                            <input v-model="searchText" type="search" class="form-control" placeholder="Buscar..." @search="filterProducts()">
                            <button type="button" class="btn btn-primary" @click="filterProducts()">Buscar</button>
                        </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <h2>{{ currentSubfamily ? currentSubfamily['subFamilyCode']['description'] : '' }}</h2>
                        <div class="search-input input-group d-none d-md-flex">
                            <input v-model="searchText" type="search" class="form-control" placeholder="Buscar..." @search="filterProducts()">
                            <button type="button" class="btn btn-primary" @click="filterProducts()">Buscar</button>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 col-md-5 col-lg-5 text-center">
                        <img class="main-product-image object-image w-100" :src="getCurrentSubfamilySrcImage()" height="400" :onerror="`this.src='${cdnRoot}/catalogo/recursos/imagen-no-disponible.png'`">
                      <!--<div class="d-flex align-items-center legend-text">
                         <div>S</div>
                         <span>Dosificación por sistema</span>
                      </div>-->
                  </div>
                  <div class="col-12 col-md-7 col-lg-7">
                      <p class="product-description mt-3 mx-md-3">
                          <span>{{ currentSubfamily ? currentSubfamily['subFamilyCode']['description2'] : '' }}</span>
                      </p>
                  </div>
              </div>
              <hr>
              <div class="row">
                  <h2 class="mb-0">Productos {{ currentSubfamily ? currentSubfamily['subFamilyCode']['description'] : '' }}</h2>
                   <!-- Item -->
                    <div v-bind:class="product['blocked'] === 'true' || product['enterTariff'] === 'false' ? 'col-12 col-md-6 col-lg-3 p-0 col-products hidden' : 'col-12 col-md-6 col-lg-3 p-0 col-products'"
                         v-for="product in filteredProducts" :key="product['@id']">
                      <div class="card product-card">
                         <img v-if="currentSubfamilyType === subfamilyType.EQUIPMENT_SUBFAMILY" :src="getProductSrcImage(product)" class="card-img-top product-image" :onerror="`this.src='${cdnRoot}/catalogo/recursos/imagen-no-disponible.png'`">
                         <div class="card-body">
                           <h5 class="card-title">{{ product['description'].toLowerCase() }}</h5>
													 <span v-if="currentSubfamilyType === subfamilyType.EQUIPMENT_SUBFAMILY" class="card-code">{{ product['productCode'] }}</span>
                           <p class="card-text">{{ product['description2'] }}</p>
                           <a v-if="currentSubfamilyType === subfamilyType.CHEMICAL_SUBFAMILY" href="#productModal" class="w-50 btn btn-principal" data-bs-toggle="modal" @click="currentProduct = product"
                           style="text-transform: none;">Ver</a>
                         </div>
                      </div>
                    </div>
              </div>
          </div>
      </main>
    	<ProductDetail :item="currentProduct" :relatedItems="getRelatedProducts()" :subfamily="currentSubfamily" :subfamilyType="currentSubfamilyType" />
        <CounselingForm />
    </div>
  
</template>

<script>
import api from "../api/nitida.js";
import BaseComponent from "./common/BaseComponent";
import ProductDetail from "./ProductDetail";
import CounselingForm from "./CounselingForm";

export default {
  name: "BusinessAreaProductsList", 
  mixins: [BaseComponent],
    components: {
        ProductDetail,
        CounselingForm
 	}, 
  data() {
    return {
        areaId: null,
		subFamiliesByChemical: [],
		subFamiliesByEquipment: [],
		currentSubfamily: null,
		currentSubfamilyType: -1,
     	products: [],
     	filteredProducts: [],
		currentProduct: null,
		searchText: null,
	 	hostname: process.env.VUE_APP_MGNL_HOST,
    };
  },
	computed: {
		areas() {
			return this.$store.state.nitida.areas;
		},
		currentArea() {
			return this.$store.state.nitida.currentArea;
		},
		staticRoot() {
      return process.env.VUE_APP_MGNL_STATIC;
    }
	},
	watch: {
		'$store.state.nitida.areas': function() {
            if (this.areas.length > 0 && !this.currentArea) {
                this.getSubfamiliesByAreaId(this.areaId);
            }
		},
		'$store.state.nitida.detailNavigation': function() {
			if (!this.$store.state.nitida.detailNavigation.show && this.currentSubfamily) {
				this.currentSubfamily = null;
				this.currentSubfamilyType = -1;
			}
		}        

	},
  methods: {
		getSubfamiliesByAreaId(areaId) {
            document.location.href="#top";
            this.areaId = areaId;
            
            if (this.areas.length == 0) {
                return;
            }

			const currentArea = this.areas.find(x => x['@id'] === areaId);

			this.$store.commit('nitida/setCurrentArea', currentArea);

			if (!currentArea) {
				return null;
			}
			
			this.subFamiliesByChemical = [];
			for (let i in this.currentArea['subFamiliesByChemical']['@nodes']) {
				this.subFamiliesByChemical.push(this.currentArea.subFamiliesByChemical[i]);
			}
			
			this.subFamiliesByEquipment = [];
			for (let i in this.currentArea['subFamiliesByEquipment']['@nodes']) {
				this.subFamiliesByEquipment.push(this.currentArea.subFamiliesByEquipment[i]);
			}
			
			console.log(`*** AREA: ${JSON.stringify(this.currentArea['@name'])} - SUBFAMILIES BY PRODUCT: ${JSON.stringify(this.subFamiliesByChemical)} - SUBFAMILIES BY EQUIPMENT: ${JSON.stringify(this.subFamiliesByEquipment)}`);
			
			if (!this.isMobile() && this.subFamiliesByChemical.length > 0) {
				this.currentSubfamily = this.subFamiliesByChemical[0];
				this.loadProducts(this.currentSubfamily, this.subfamilyType.CHEMICAL_SUBFAMILY);
			}
		},
		getCurrentSubfamilySrcImage() {
			const name = `${this.currentSubfamily['subFamilyCode']['familyCode']}${this.currentSubfamily['subFamilyCode']['code']}`;
			const srcImage = `${this.cdnRoot}/imagenes/${name}.jpg`;
			console.log(`*** getCurrentSubfamilyImage() called! [ src: '${srcImage}' ]`);
			return srcImage;
			//return '/assets/comp_palm.jpeg';
		},
		loadProducts(subfamily, type) {
            document.location.href="#top";            
			this.currentSubfamily = subfamily;
			this.currentSubfamilyType = type;

			this.$store.commit('nitida/setDetailNavigation', { title: this.currentArea['title'] + '/' + this.currentSubfamily['subFamilyCode']['description'], show: true });

			console.log(`*** loadProducts() called! [ subfamily: ${JSON.stringify(this.currentSubfamily)} ]`);

			const me = this;

			api.getProductsBySubfamily(this.currentSubfamily).then((response) => {
				if (me.currentSubfamilyType === this.subfamilyType.CHEMICAL_SUBFAMILY) {
					me.productMap = this.groupProductsByDescription(response);

					me.products = [];
					[...me.productMap.keys()].forEach(key => {
						me.products.push(me.productMap.get(key)[0]);
					});
				} else {
					me.productMap = null;
					me.products = response;
				}
				this.filteredProducts = [...this.products];
				console.log(`*** AREA: ${JSON.stringify(this.currentArea['@name'])} - SUBFAMILY: '${JSON.stringify(this.currentSubfamily['subFamilyCode']['description'])} - PRODUCTS: ${JSON.stringify(this.products)}`);
			});
		},
		groupProductsByDescription(products) {
			const map = new Map();
    	products.forEach((item) => {
        const key = item['description'];
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
			});
			return map;
		},
		getRelatedProducts() {
			if (this.currentSubfamilyType !== this.subfamilyType.CHEMICAL_SUBFAMILY || !this.currentProduct) {
				return [];
			}
			return this.productMap.get(this.currentProduct['description']);
		},
		filterProducts() {
			if (this.products.length === 0 || !this.searchText || this.searchText.trim().length === 0) {
				this.filteredProducts = [...this.products];
				console.log(`*** FILTERED PRODUCTS: ${this.filteredProducts.length}`);
				return;
			} 
			this.filteredProducts = this.products.filter(x => x['description'].toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1 
				|| x['description2'].toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1);

			console.log(`*** FILTERED PRODUCTS: ${this.filteredProducts.length}`);	
		},
		getProductSrcImage(product) {
			const src = `${this.cdnRoot}/imagenes/${product['productCode']}.jpg`;
			console.log(`*** getProductImage() called! [ src: '${src}' ]`);
			return src;
			//return '/assets/comp_palm.jpeg';
		}
  },
	beforeMount() {
        document.location.href="#top";
		this.getSubfamiliesByAreaId(this.$route.query.area);
 	} 
};
</script>

<style>
/*** Breadcrumb ***/
.breadcrumb-item {
    font-size: 0.813rem;
}
.breadcrumb-item a {
    color: #009CBD;
    text-decoration: underline;
    font-size: 0.813rem;
    padding: 0 0 0 10px;
    margin: 0;
    display: inline;
}
.breadcrumb-item a:hover {
    background-color: transparent;
    color: #009CBD;
}
.breadcrumb-item.active {
    color: #6c757d;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    max-width: 70%;
}
.back-btn {
		display: none; 
}
@media only screen and (max-width: 576px) {
	/*.main-products nav {
        position: absolute;
        top: 10px;
    }*/
    .breadcrumb-item a {
        padding: 0;
    }
	.back-btn {
		display: block; 
	}
    .main-product-image {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center;
    } 
    .products-container {
        padding: 0;
    }   
}
</style>