<template>
  <div class="Module">
      <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-1">
                  <div :class="cardClass">
                      <div class="card-body p-1">
                       <!--<h2 class="hint">[Module Page]</h2>-->
                       <div class="card-title">
                          <i v-bind:class="icon"></i>
                          <h1 class="titlePage">{{ title || 'Nulla vitae elit libero, a pharetra augue.' }}</h1>
                       </div>
                       <EditableArea v-if="main" v-bind:content="main" />
                      </div>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { EditableArea } from '@magnolia/vue-editor';

export default {
  name: "Module",
  components: {
    EditableArea
  },
  props: ["title", "icon", "metadata", "main"],	
	data() {
    return {
			cardClass: 'card',
    };
  },
	watch: {
		'$store.state.nitida.detailNavigation': function() {
			this.cardClass = `card${this.$store.state.nitida.detailNavigation.show ? ' card-no-menu' : ''}`;
		}
	}
};
</script>

<style scoped>

/*** Module Template ***/
.Module {
    margin-top: 70px; 
    width: 100%;
}
.Module .card {
    height: calc(100vh - 85px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.Module .card-title {
    display: flex;
    align-items: center;
}
.Module .card-title i {
    color: #009CBD;
}
.Module .card-title .titlePage {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-left: 5px;
}
.Module .card.card-no-menu {
    overflow: hidden;
}
@media only screen and (max-width: 768px) {
    .Module .card-title .titlePage,
    .Module .card-title i {
        display: none;
    }
    .Module {
        margin-top: 85px; 
    }
    .Module .card {
        height: calc(100vh - 150px);
        -webkit-overflow-scrolling: touch;
    }
    .Module .card.card-no-menu {
        height: calc(100vh - 120px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}
</style>
