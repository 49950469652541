import utils from './apiUtils.js'

export default {

	async getAreas() {
		const enabledFilter = 'enabled=true';
    const orderByFilter = 'orderBy=order%20asc';

		const url = `${process.env.VUE_APP_MGNL_API_AREAS}?${enabledFilter}&${orderByFilter}`;
		const res = await fetch(url);
		const data = await res.json();
		const areas = data.results;

		console.log(`*** AREAS DE NEGOCIO: ${JSON.stringify(areas.map(x => x['name']))}`);
		
		window.$store.commit('nitida/setAreas', areas);
	},
	async getNews() {
		const today = new Date().toISOString().substring(0, 10);

		const activeFilter = `publishedDate%5Blte%5D=${today}&finishPublishedDate%5Bgt%5D=${today}`;
		const orderByFilter = 'orderBy=hightlighted%20desc,publishedDate%20desc';

		const url = `${process.env.VUE_APP_MGNL_API_NEWS}?${activeFilter}&${orderByFilter}`;
		const res = await fetch(url);
		const data = await res.json();
		const news = data.results;

		console.log(`*** NOTICIAS: ${JSON.stringify(news.map(x => x['title']))}`);
		
		window.$store.commit('nitida/setNews', news);
	},
	async getProductsBySubfamily(subfamily) {
		const activeFilter = 'active=true';
        const showProductFilter = 'blocked=false&enterTariff=true';
		const orderByFilter = 'orderBy=updateDate%20desc';

		const url = `${process.env.VUE_APP_MGNL_API_PRODUCTS}${subfamily['subFamilyCode']['@path']}@nodes?${activeFilter}&${showProductFilter}&${orderByFilter}`;
		const res = await fetch(url);
		const data = await res.json();
		return data;
	},
  async getIncidences() {
		let clientCode = null;

        if(window.$store.state.nitida.currentClient) {
            clientCode = window.$store.state.nitida.currentClient.clientCode;            
        } else {
            clientCode = window.$store.state.nitida.userInfo.clientCode;
        }

		const enabledFilter = 'active=true';
		const orderByFilter = 'orderBy=openDate%20desc';
		const url = `${process.env.VUE_APP_MGNL_API_INCIDENCES}/${clientCode.replace("/", "-")}@nodes?${enabledFilter}&${orderByFilter}`;
		const res = await fetch(url);
		const data = await res.json();
		let incidences = [];    
	
		//TODO: FIXME: Esto se debe de hacer en servidor
		if (data) {

            let toDate = new Date();
            //toDate.setHours(0,0,0,0);

            let fromDate = new Date(toDate);
            fromDate.setFullYear(fromDate.getFullYear() - 1); 

            console.log('****toDate****: ' + toDate);   
            console.log('****fromDate****: ' + fromDate);

            incidences = data.filter(x => new Date(x.openDate).getTime() >= fromDate.getTime() && new Date(x.openDate).getTime() <= toDate.getTime());  
              
            console.log('****toDate.getTime()****: ' + toDate.getTime());   
            console.log('****fromDate.getTime()****: ' + fromDate.getTime());            

			incidences = incidences.sort(function(a, b) {
				return b.incidenceCode.localeCompare(a.incidenceCode);
			});
		}

		console.log(`*** INDICENCIAS: ${JSON.stringify(incidences.map(x => x['incidenceCode']))}`);
		window.$store.commit('nitida/setIncidences', incidences);           
	},
	async getUserInfo() {
		const options = {
			method: 'GET',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('vue-token')}`
			}
		};
		const url = `${process.env.VUE_APP_MGNL_API_SERVICE}/me`;
		const res = await fetch(url, options);
		const userInfo = await res.json(); 

		console.log(`*** USERINFO: ${JSON.stringify(userInfo)}`);

		if (userInfo.title == 'COMERCIALN' || userInfo.title == 'DIRECCION') {
			this.getClients(userInfo);
			return;
		}
	
		window.$store.commit('nitida/setUserInfo', userInfo);
  },
  async getClients(userInfo) {
		const options = {
			method: 'GET',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('vue-token')}`
			}
		};
		
		const url = `${process.env.VUE_APP_MGNL_API_CLIENTS}`;
		const res = await fetch(url, options);
		const data = await res.json();
		let clients = [];

		if (data) {
			clients = data;
		}

		console.log(`*** CLIENTES: ${JSON.stringify(clients)}`);
		
		window.$store.commit('nitida/setClients', clients);

		if (clients.length > 0) {
			window.$store.commit('nitida/setUserInfo', userInfo);
			window.$store.commit('nitida/setCurrentClient', clients[0]);
			return;
		} 
    
		window.$store.commit('nitida/setUserInfo', userInfo);
		window.$store.commit('nitida/setCurrentClient', null);
	},
	sendContactMail(data, cbok, cberr) {
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('vue-token')}`
			},
			body: JSON.stringify(data)
		};

		const url = process.env.VUE_APP_MGNL_API_CONTACT;
		const res = fetch(url, options);
		res.then(res => {			
			if (!res.ok) {
				if (cberr) {
					cberr({ status: res.status, message: res.statusText });
				}
			} else {
				if (cbok) {
					cbok();
				}
			}
		});
	},
	createIncidence(data, cbok, cberr) {
		var headers = {};
		headers['Authorization'] = `Bearer ${localStorage.getItem('vue-token')}`;
		if (window.$store.state.nitida.currentClient) {
			headers['X-CLIENT'] = window.$store.state.nitida.currentClient.clientCode;
		}

		const options = {
			method: 'POST',
			headers: headers,
			body: data
		};

		const url = process.env.VUE_APP_MGNL_API_INCIDENCE;
		const res = fetch(url, options);
		res.then(res => {
			if (!res.ok) {
				if (cberr) {
					cberr({ status: res.status, message: res.statusText });
				}
			} else {
				if (cbok) {
					cbok();
				}
			}
		});
	},
	async getDocumentConfig() {
		var headers = {};
		headers['Authorization'] = `Bearer ${localStorage.getItem('vue-token')}`;
		if (window.$store.state.nitida.currentClient) {
			headers['X-CLIENT'] = window.$store.state.nitida.currentClient.clientCode;
		}
		
		const options = {
			method: 'GET',
			headers: headers
		};

		const url = `${process.env.VUE_APP_MGNL_API_DOCUMENTS}/config`;
		const res = await fetch(url, options);
		const data = await res.json();   

		console.log(`*** DOCUMENT CONFIG: ${JSON.stringify(data)}`);
	
		window.$store.commit('nitida/setDocumentConfig', data);
  },
  async getNotifications() {
		var headers = {};
		headers['Authorization'] = `Bearer ${localStorage.getItem('vue-token')}`;
		if (window.$store.state.nitida.currentClient) {
			headers['X-CLIENT'] = window.$store.state.nitida.currentClient.clientCode;
		}
		
		const options = {
			method: 'GET',
			headers: headers
		};

		const url = `${process.env.VUE_APP_MGNL_API_SERVICE}/notifications`;
		const res = await fetch(url, options);
		const data = await res.json();   

		console.log(`*** NOTIFICATIONS: ${JSON.stringify(data)}`);
	
		window.$store.commit('nitida/setNotifications', data);
  },
	async getDocumentsByFolderId(folderId) {
		var headers = {};
		headers['Authorization'] = `Bearer ${localStorage.getItem('vue-token')}`;
		if (window.$store.state.nitida.currentClient) {
			headers['X-CLIENT'] = window.$store.state.nitida.currentClient.clientCode;
		}
		
		const options = {
			method: 'GET',
			headers: headers
		};

		const url = `${process.env.VUE_APP_MGNL_API_DOCUMENTS}/folder/${folderId}`;
		const res = await fetch(url, options);
		const data = await res.json();   

		console.log(`*** DOCUMENTS BY FOLDER ID ${folderId}: ${JSON.stringify(data)}`);
		
		return data;
  },
	async downloadDocument(document, cbok, cberr) {
		var headers = {};
		headers['Authorization'] = `Bearer ${localStorage.getItem('vue-token')}`;
		if (window.$store.state.nitida.currentClient) {
			headers['X-CLIENT'] = window.$store.state.nitida.currentClient.clientCode;
		}
		
		const options = {
			method: 'GET',
			headers: headers
		};

		let filename = 'document.pdf';

		const url = `${process.env.VUE_APP_MGNL_HOST}${document}`;
		const res = await fetch(url, options)
		.then(response => {
			const disposition = response.headers.get('Content-Disposition');
			if (disposition && disposition.indexOf('attachment') !== -1) {
				var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) { 
				filename = matches[1].replace(/['"]/g, '');
				}
			}
			return response.blob();
		})
		.then(blob => {
				utils.downloadDocument(filename, blob);
				if (cbok) {
					cbok();
				}
		})
		.catch(reason => {
			if (cberr) {
					cberr(reason);
				}
		});
  }
}