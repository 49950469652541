<template>
  <div class="modal modalIncidences" tabindex="-1" id="incidencesModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
      <div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{ item ? item['incidenceCode'] : '----' }}</h4>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-2">
                                <div class="incidence-text-detail"><span>{{ $t('sat.detail.incidenceCode') }}</span> {{ item ? item['incidenceCode'] : '----' }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-2">
                                <div class="incidence-text-detail"><span>{{ $t('sat.detail.incidenceType') }}</span> {{ item ? item['incidenceType'] : '----' }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-2">
                                <div class="incidence-text-detail"><span>{{ $t('sat.detail.openDate') }}</span> {{ item ? formatDate(item['openDate']) : '----' }}</div>
                            </div>
                            <div class="col-12 col-lg-6 mb-2">
                                 <div class="incidence-text-detail"><span>{{ $t('sat.detail.closeDate') }}</span> {{ item && formatDate(item['closeDate']) != null ? formatDate(item['closeDate']) : '----' }}</div>
                            </div>
                            <div class="col-12 mb-2">
                                 <div class="incidence-text-detail"><span>{{ $t('sat.detail.incidenceState') }}</span> {{ item ? item['incidenceState'] : '----' }}</div>
                            </div>
                            <div class="col-12 mb-2">
                                 <div class="incidence-text-detail">
                                     <span>{{ $t('sat.detail.attachments') }}</span><br>
                                     <ul>
                                        <li v-for="document in documents" :key="document['@id']">
                                           <a :href="document ? document['docURL'] : '#'" target="_blank">{{ document ? document['docDesc'] : '----' }}</a>
                                        </li>
                                     </ul> 
                                 </div>
                            </div>
                            <div class="col-12 mb-2">
                                <div class="incidence-text-detail">
                                    <span>{{ $t('sat.detail.incidenceDescription') }}</span><br> {{ item ? item['incidenceDescription'] : '----' }}{{ item ? item['incidenceDescription2'] : '----' }}
                                </div>
                            </div>
                           <!--<div class="col-12 mb-2">
                                <div class="incidence-text-detail">
                                    <span>{{ $t('sat.detail.incidenceRelatedArticles') }}</span><br> {{ item ? item['incidenceDescription2'] : '----' }}
                                </div>
                            </div>-->                           
                            <div class="col-12 mb-2">
                                <div class="incidence-text-detail">
                                    <span>{{ $t('sat.detail.incidenceActions') }}</span>
                                    <ul>
                                       <li class="d-flex" v-for="action in actions" :key="action['@id']">
                                          <div class="text-left me-3">{{ action ? formatDate(action['actionDate']) : '----' }}</div>
                                          <div class="text-right"> {{ action ? action['description'] : '----' }}</div>
                                   </li>
                                </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary btn-flat" data-bs-dismiss="modal">{{ $t('common.close') }}</button>
				</div>
			</div>
    </div>
  </div>
</template>
 
<script>
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: 'IncidencesDetail',
  props: ['item'],
  mixins: [BaseComponent],
 data() {
    return {
		documents: [],
        actions: [],
    };
  },
 watch:{
     item(){
        this.actions = [];

		if (this.item['actions'] && this.item['actions']['@nodes']) {
			for (let i in this.item['actions']['@nodes']) {
                const pos = this.item['actions']['@nodes'][i];
                this.actions.push(this.item['actions'][pos]);
			}    
		}

        this.documents = [];

		if (this.item['documents'] && this.item['documents']['@nodes']) {
			for (let i in this.item['documents']['@nodes']) {
                const pos = this.item['documents']['@nodes'][i];
				this.documents.push(this.item['documents'][pos]);
			}    
		}
                  
     } 
  }     
}
</script>

<style>

</style>
