<template>
  <div>
    <div class="container-fluid p-0 d-block d-md-flex DocAsideList">
      <aside ref ="aside" :class="`bd-sidebar DocAsideListContainer${selectedNode ? ' phone-hidden' : ''}`">
        <nav class="collapse show bd-links">
          <ul v-for="level1 in documentConfig.children" :key="level1.id" class="list-unstyled mb-0 py-0 py-md-3 pt-md-1">
						<h3>
              <i class="fa-solid fa-folder fa-lg"></i>
            	<span>{{ level1.name.toUpperCase() }}</span>
          	</h3>
						<li v-for="level2 in level1.children" :key="level2.id" class="mb-1">
							<button class="btn d-inline-flex collapsed" data-bs-toggle="collapse" :data-bs-target="`#prod-sist-doc-${level2.id}`" aria-expanded="false">
									<span><i class="fa-solid fa-folder-closed me-1"></i>{{ level2.name }}</span>
									<i class="fa-solid fa-chevron-down transform-icon"></i>
							</button>
							<div class="collapse" :id="`prod-sist-doc-${level2.id}`">
								<ul class="list-unstyled fw-normal pb-1 small">
									<li v-for="level3 in level2.children" :key="level3.id" role="button" class="doc-type-list">
										<a :class="`d-inline-flex align-items-center${selectedNode && selectedNode.id === level3.id ? ' active' : ''}`" @click="selectNode(level3)">{{ level3.name }}</a>
										<div v-if="level3.notifications>0 && userInfo.title!='DIRECCION'" class="new-doc-list-bubble">{{level3.notifications}}</div>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</nav>
			</aside>

			<main v-if="selectedNode" class="main-docs-section order-1">
				<!-- Folder Title -->
				<div class="container-fluid doc-section mt-2">
					<div class="row">
						<div class="col-md-12">
							<div class="d-block d-md-flex align-center justify-content-between">
								<h1 class="doc-section-title d-none d-md-block">
									<i class="fa-solid fa-folder-plus"></i>                                         
                                        {{ selectedNode ? selectedNode.name : '' }}
								</h1>
								<div class="d-flex align-items-center justify-content-center mb-4">
									<label class="me-2" style="color: #000000; font-size: 0.813rem">{{ $t('documents.filter-by') }}</label>
									<button type="button" id="date" @click="toggleFilterDate()" :class=" `btn-link-filter ${filterMode === 'date' ? ' active' : ''} pb-1`" href="#">
										<i :class="`fa-lg fa-solid ${filterDateDesc ? 'fa-arrow-down-9-1' : 'fa-arrow-down-1-9'}`"></i>
										{{ $t('documents.filter-by-date') }}
									</button>
									<span style="margin: 0 5px;">|</span>
									<button type="button" id="name" @click="toggleFilterName()" :class=" `btn-link-filter ${filterMode === 'name' ? ' active' : ''} pb-1`" aria-current="page">
										<i :class="`fa-lg fa-solid ${filterNameDesc ? 'fa-arrow-down-z-a' : 'fa-arrow-down-a-z'}`"></i>
										{{ $t('documents.filter-by-name') }}
									</button>
								</div>
							</div>
						</div>
					</div>
					<!-- DIV -->
					<div>
						<!-- Empty State -->
						<div v-if="documents.length === 0" class="empty-state-container">
							<div class="empty-state-content">
								<img src="/assets/data-not-found.svg" alt="Contenido vacío" width="120">
								<h2>{{ $t('documents.no-documents') }}</h2>
								<p>{{ $t('documents.no-documents-yet') }}</p>
							</div>
						</div>
						<!---->
						<!-- Tabla Desktop --->
						<div class="row" v-if="!isMobile()">
							<div class="col-md-12">
								<table class="table" v-if="documents.length > 0 && selectedNode.displayType === 'list'">
									<thead>
										<tr>
											<th scope="col">{{ $t('documents.table.created') }}</th>
											<th scope="col">{{ $t('documents.table.document') }}</th>
											<th scope="col">{{ $t('documents.table.format') }}</th>
											<th scope="col"></th> 
										</tr>
									</thead>
									<tbody>
										<tr v-for="(document, index) in documents" :key="index" @click="downloadDocument(document)">
											<td class="date-column" scope="row">{{ document ? formatDateTime(document.modified) : '' }} <span class="badge rounded-pill bg-danger">{{ displayBadge(document) ? $t('documents.new') : ''}}</span></td>
											<td scope="row">{{ document ? document.caption.toUpperCase() : '' }}</td>
											<td scope="row">PDF</td>
											<td class="date-column" scope="row"><i class="fa-solid fa-download"></i></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div> 
						<!---->
						<!-- Tabla Detalle Desktop --->
						<div class="row" v-if="!isMobile()">
							<div class="col-md-12">
								<table class="table" v-if="documents.length > 0 && selectedNode.displayType === 'listDetail'">
									<thead>
										<tr>
											<th scope="col">{{ $t('documents.order-date') }}</th>
											<th scope="col">{{ $t('documents.your-reference') }}</th>
											<th scope="col">{{ $t('documents.your-reference-2') }}</th> 
											<th scope="col">{{ $t('documents.ship-to-name') }}</th>     
											<th scope="col">{{ $t('documents.shipment-date') }}</th>                                                                                                                                                                         
											<th scope="col">{{ $t('documents.status') }}</th>
                                            <th scope="col"></th>                                                                                            
										</tr>
									</thead>
									<tbody>
										<tr v-for="(document, index) in documents" :key="index">
                                            <td class="date-column" scope="row">{{ document ? formatDateTime(document.additionalProperties['extra_order_book_date']) : '' }} <span class="badge rounded-pill bg-danger">{{ displayBadge(document) ? $t('documents.new') : ''}}</span></td>
											<td scope="row">{{ document ? document.additionalProperties['extra_your_reference'] : '' }}</td>
                                            <td scope="row">{{ document ? document.name.toUpperCase() : '' }}</td>
											<td scope="row">{{ document ? document.additionalProperties['extra_ship_to_name'] : '' }}</td>                                                                                     
											<td class="date-column" scope="row">{{ document && getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).orderStatusNum !== '1' ? formatDateTime(document.additionalProperties['extra_shipment_date']) : '' }}</td>                                                                                         
                                            <td scope="row" :style="'font-weight: 600; color:'+getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).color">
                                                {{ getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).orderStatus }}
                                                <i class="fa-solid fa-download"
                                                v-if="getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).orderStatusNum === '2'"
                                                @click="downloadPartialOrderDocument(document)"></i>
                                            </td>                                             
                                            <td scope="row">
                                                <i class="fa-solid fa-download" 
                                                   v-if="getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).downloadPDF === true"
                                                   @click="downloadDocument(document)"></i>
                                            </td>
										</tr>
									</tbody>
								</table>
							</div>
						</div> 
						<!---->                        
						<!-- Grid Desktop -->
						<div class="row g-3" v-if="!isMobile() && selectedNode.displayType === 'thumbnail'">
							<!-- Item -->
							<div v-for="(document, index) in documents" :key="index" class="col-12 col-sm-6 col-lg-3 d-none d-md-block">
								<div class="catalog-item-container" v-if="document.size!==0">
									<div class="catalog-image-container" :style="`background-image: url(${previewDocument(document)})`" />
									<h2>{{ document ? document.caption.toUpperCase() : '' }}</h2>
									<p class="doc-date">{{ document ? formatDateTime(document.modified) : '' }} <span class="badge rounded-pill bg-danger">{{ displayBadge(document) ? $t('documents.new') : ''}}</span></p>
									<a role="button" class="download-btn download-title" @click="downloadDocument(document)">
										{{ $t('common.download') }}
									</a>
								</div>
							</div>
							<!---->
						</div>
						<!---->
						<!-- Listado Phone -->
						<div class="row" v-if="isMobile() && selectedNode.displayType === 'list'">
							<div v-for="(document, index) in documents" :key="index" class="col-md-12">
								<!-- Item -->
								<div class="list-group px-0" @click="downloadDocument(document)">
									<a href="#" data-bs-toggle="modal" class="list-group-item list-group-item-action mt-1 px-2" aria-current="true">
                                        <div class="d-flex w-100 justify-content-between align-items-center mt-2">
                                            <div class="d-flex flex-column">
                                                <label>{{ $t('documents.table.created') }}</label>
                                                <span class="doc-date">{{ document ? formatDateTime(document.modified) : '' }} <span class="badge rounded-pill bg-danger">{{ displayBadge(document) ? $t('documents.new') : ''}}</span></span>
                                            </div>
                                            <div>
                                                <label>{{ $t('documents.table.format') }}</label>
                                                <p class="incidence-description mb-0">PDF</p>
                                            </div>
											
										</div>
										<div class="d-flex w-100 justify-content-between align-items-center">
                                            <div>
                                                <label>{{ $t('documents.table.document') }}</label>
 											    <h5 class="doc-title mb-0">{{ document ? document.caption.toUpperCase() : '' }}</h5>
                                            </div>
											<div class="doc-list-phone mt-3">
												<!--<span class="doc-date">{{ document ? formatDateTime(document.modified) : '' }}</span>-->
												<i class="fa-solid fa-download"></i>
											</div>
										</div>
									</a>
								</div>
								<!---->
							</div>
						</div> 
						<!---->
                        <!-- Listado Detalle Phone - Pedidos ventas -->
                        <div class="row" v-if="isMobile() && selectedNode.displayType === 'listDetail'">
                            <div v-for="(document, index) in documents" :key="index" class="col-md-12">
                                <!-- Item -->
                                <div class="list-group px-0">
                                    <a href="#" data-bs-toggle="modal" class="list-group-item list-group-item-action mt-1 px-2" aria-current="true">
                                        <!-- Nº pedidos -->
                                        <div class="d-flex justify-content-between" style="width: 90%">
                                            <div class="w-50">
                                                <label>{{ $t('documents.order-date') }}</label>
                                                <h5 class="doc-date">{{ document ? formatDateTime(document.additionalProperties['extra_order_book_date']) : '' }} <span class="badge rounded-pill bg-danger">{{ displayBadge(document) ? $t('documents.new') : ''}}</span></h5>
                                            </div>
                                            <div class="w-50 px-1">
                                                <label>{{ $t('documents.your-reference') }}</label>
                                                <h5 class="doc-title mb-0">{{ document ? document.additionalProperties['extra_your_reference'] : '' }}</h5> 
                                            </div>
                                        </div>
                                        <!-- Icono de descarga -->
                                        <div class="doc-list-phone-alt">
                                            <i class="fa-solid fa-download" 
                                               v-if="getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).downloadPDF === true"
                                               @click="downloadDocument(document)"></i>
                                        </div>
                                        <!-- Fechas -->
                                        <div class="d-flex justify-content-between" style="width: 90%">
                                            <div class="w-50">
                                                <label>{{ $t('documents.shipment-date') }}</label>
                                                <h5 v-if="getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).orderStatusNum !== '1'"
                                                    class="doc-date">
                                                        {{ document ? formatDateTime(document.additionalProperties['extra_shipment_date']) : '' }}
                                                </h5> 
                                            </div>
                                            <div class="w-50 px-1">
                                                <label>{{ $t('documents.your-reference-2') }}</label>
                                                <h5 class="doc-title mb-0">{{ document ? document.name.toUpperCase() : '' }}</h5> 
                                            </div>
                                        </div>
                                        <!-- Estado -->
                                        <div>
                                            <label>{{ $t('documents.status') }}</label>
                                            <h5 :style="'font-weight: 600; color:'+getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).color">
                                                {{ getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).orderStatus }}
                                                <i class="fa-solid fa-download"
                                                v-if="getSalesOrderStatus(document.additionalProperties['extra_status'], document.additionalProperties['extra_order_pending']).orderStatusNum === '2'"
                                                @click="downloadPartialOrderDocument(document)"></i>                                             
                                            </h5>                                           
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                                             
						<!-- Grids Phone-->
						<div class="col-12 col-sm-6 col-lg-3 d-block d-md-none" v-if="isMobile() && selectedNode.displayType === 'thumbnail'">
							<div v-for="(document, index) in documents" :key="index" class="catalog-item-container">
                                <div v-if="document.size!==0">
                                    <a role="button" href="#">
                                        <img width="200" height="200" :src="previewDocument(document)" :alt="document ? document.caption : ''">
                                    </a>
                                    <a href="#">
                                        <h2>{{ document ? document.caption.toUpperCase() : '' }}</h2>
                                        <p class="doc-date">{{ document ? formatDateTime(document.modified) : '' }} 
                                            <span class="badge rounded-pill bg-danger">{{ displayBadge(document) ? $t('documents.new') : ''}}</span>
                                        </p>
                                    </a>
                                    <a class="download-btn download-title" @click="downloadDocument(document)">
                                        {{ $t('common.download') }}
                                    </a>
                                </div>
							</div>
						</div>
						<!---->
					</div>
                    <!-- Spinner -->
                        <!-- DIV -->
                        <div class="loading-container" id="spinner" style="display:none;">
                            <div class="spinner-border text-info" role="status">
                                <span class="visually-hidden">{{ $t('common.loading') }}</span>
                            </div>
                        </div> 
			</div>
			<!---->
		</main>
  </div>
</div>
</template>



<script>
import api from "../api/nitida.js";
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: "DocumentationMain",
  mixins: [BaseComponent],
  components: {
  },  
  
  data() {
    return {
	  selectedNode: null,
	  documents: [],
      filterMode: 'date',
      filterDateDesc: true,
      filterNameDesc: false,
	  notifications:[],
	  documentConfig:[],
	  initCompleted: false,
      saleOrderStatus: null,
      saleOrderPending: null,
      currentDate: null,
      lastCurrentDate: null
    }
  },
	mounted() {
		$('#spinner').hide(); 
        this.updateDocumentConfig();

        this.currentDate = new Date();
        this.currentDate.setHours(0,0,0,0);
        this.lastCurrentDate = new Date(this.currentDate);

        this.lastCurrentDate.setFullYear(this.lastCurrentDate.getFullYear() - 1); 

        console.log('****currentDate****: ' + this.currentDate);   
        console.log('****lastCurrentDate****: ' + this.lastCurrentDate);          		
	},
  computed: {
		currentClient() {
			return this.$store.state.nitida.currentClient;
		},
        userInfo() {
            return this.$store.state.nitida.userInfo;
        }        
  },
  methods: {
        getSalesOrderStatus(saleOrderStatus, saleOrderPending){
            var saleOrder = new Object();
            saleOrder.orderStatus = null;
            saleOrder.orderStatusNum = null;
            saleOrder.color = null;
            saleOrder.downloadPDF = false;            

            if (saleOrderStatus === 'Abierto') {
                saleOrder.orderStatus = this.$t('documents.order-preparing');
                saleOrder.orderStatusNum = '1';
                saleOrder.color = '#ffd400';
                saleOrder.downloadPDF = true;
            } else if (saleOrderStatus === 'Lanzado') {

                if(saleOrderPending === 'true') {
                    saleOrder.orderStatus = this.$t('documents.order-partial-shipment');
                    saleOrder.orderStatusNum = '2';
                    saleOrder.color = '#ffa500'; 
                    saleOrder.downloadPDF = true;                                       
                } else {
                    saleOrder.orderStatus = this.$t('documents.order-send');
                    saleOrder.orderStatusNum = '3';
                    saleOrder.color = '#008000';  
                    saleOrder.downloadPDF = true;                                       
                }
            } else {
                return saleOrder;
            }
            return saleOrder;
        },
        
		displayBadge: function (item) {
			if (!this.notifications || !this.notifications.items) return false;
			return this.notifications.items.find (it => it == item.id);
		},
		clearBadge(item) {
			if (!this.$store.state.nitida.notifications || !this.selectedNode) return;

			let allNotifications = JSON.parse(JSON.stringify(this.$store.state.nitida.notifications));
			let currentCatId = this.selectedNode.id;
			if (currentCatId.indexOf(':')>0) {
				currentCatId = currentCatId.split(':')[1];
			}
			let catNotifications= allNotifications.find(cat => cat.id == currentCatId);
			var index = catNotifications.items.indexOf(item.id);
			if (index !== -1) {
				try {
				catNotifications.items.splice(index,1);
				this.$store.commit('nitida/setNotifications', allNotifications);
				} catch (error) {
  					console.log(error);
				}
			} 
			
		},
		selectNode(node) {			
			this.$store.commit('nitida/setDetailNavigation', { title: node.name, show: true });

			console.log(`*** selectNode() called! [ node: ${JSON.stringify(node)} ]`);

			$('#spinner').show();   

			const me = this;	

			api.getDocumentsByFolderId(node.id).then((response) => {
				$('#spinner').hide();                                 

				me.selectedNode = node;
                            
                console.log('****SELECTED NODE****: ' + JSON.stringify(me.selectedNode));

                //Nodo Documentación de venta
                if (me.selectedNode.systemPath.endsWith("/salesOrder") ||
                    me.selectedNode.systemPath.endsWith("/salesDelivery") ||
                    me.selectedNode.systemPath.endsWith("/salesInvoice") ||
                    me.selectedNode.systemPath.endsWith("/salesRefund") ||
                    me.selectedNode.systemPath.endsWith("/contratos-de-arrendamiento") ||
                    me.selectedNode.systemPath.endsWith("/serviceRefund") ||
                    me.selectedNode.systemPath.endsWith("/serviceInvoice")) {   
                     
                    me.documents = response.filter(d => {
                        let date = null;	
                        
                        if (me.selectedNode.systemPath.endsWith("/salesOrder")) { //Nodo Pedidos de venta
                            date = new Date(d.additionalProperties['extra_order_book_date']);
                        } else if (me.selectedNode.systemPath.endsWith("/salesDelivery")) { //Nodo Albaranes de venta
                            date = new Date(d.modified);
                        } else if (me.selectedNode.systemPath.endsWith("/salesInvoice")) { //Nodo Facturas de venta
                            date = new Date(d.modified);                       
                        } else if (me.selectedNode.systemPath.endsWith("/salesRefund")) { //Nodo Abonos de venta
                            date = new Date(d.modified);                                               
                        } else if (me.selectedNode.systemPath.endsWith("/serviceRefund")) { //Nodo Abonos de servicios
                            date = new Date(d.modified);                       
                        } else if (me.selectedNode.systemPath.endsWith("/serviceInvoice")) { //Nodo Facturas de servicios
                            date = new Date(d.modified);                        
                        }

                        date.setHours(0,0,0,0);

                        return date.getTime() >= me.lastCurrentDate.getTime() && date.getTime() <= me.currentDate.getTime();

                        /*console.log('****DATE****: ' + date);
                        console.log('****LASTCURRENTDATE****: ' + me.lastCurrentDate);
                        console.log('****CURRENTDATE****: ' + me.currentDate);
                        console.log('****IS OK****: ' + isOk);

                        return isOk;*/
                    });
                } else {
                    me.documents = response;
                }

				let allNotifications = me.$store.state.nitida.notifications;
				let currentCatId = me.selectedNode.id;
				if (currentCatId.indexOf(':')>0) {
					currentCatId = currentCatId.split(':')[1];
				}
				me.notifications = allNotifications.find(cat => cat.id == currentCatId);

				this.doFilter();
			});
		},
		previewDocument(document) {
			if (!document) {
				return '';
			}
			return `${process.env.VUE_APP_MGNL_HOST}${document.previewUrl}`;
		},
		downloadPartialOrderDocument(document) {
			if (!document) {
				return;
			}
			$('#spinner').show();   
			const me = this;
            var variant = '?variant=partialOrderDt';
			api.downloadDocument(document.downloadUrl+variant, () => {
                console.log('DOWNLOAD PARTIAL ORDER DOCUMENT: ' + document.downloadUrl+variant);
				$('#spinner').hide();
				if (!this.currentClient) {
					me.clearBadge(document);
				}
			},
			(error) => {
				$('#spinner').hide();
			});
		},        
		downloadDocument(document) {
			if (!document) {
				return;
			}
			$('#spinner').show();   
			const me = this;
			api.downloadDocument(document.downloadUrl, () => {
                console.log('DOWNLOAD DOCUMENT: ' + document.downloadUrl);
				$('#spinner').hide();
				if (!this.currentClient) {
					me.clearBadge(document);
				}
			},
			(error) => {
				$('#spinner').hide();
			});
		},
		toggleFilterName() {
			this.filterMode = 'name';
			this.filterNameDesc = !this.filterNameDesc;
			this.doFilter();
		},
		toggleFilterDate() {
			this.filterMode = 'date';
			this.filterDateDesc = !this.filterDateDesc;
			this.doFilter();
		},
		doFilter() {
            var me = this;
            
			if (this.filterMode === 'name') {
				if (!this.filterNameDesc) {
					this.documents = this.documents.sort(function(a, b) {
						if (a.caption.toUpperCase() < b.caption.toUpperCase()) {
							return -1;
						}
						if (a.caption.toUpperCase() > b.caption.toUpperCase()) {
							return 1;
						}
						return 0;
					});
				} else {
					this.documents = this.documents.sort(function(a, b) {
						if (a.caption.toUpperCase() > b.caption.toUpperCase()) {
							return -1;
						}
						if (a.caption.toUpperCase() < b.caption.toUpperCase()) {
							return 1;
						}
						return 0;
					});
				}
			} else if (this.filterMode === 'date') {
				if (!this.filterDateDesc) {
					this.documents = this.documents.sort(function(a, b) {
                        if (me.selectedNode.displayType === 'listDetail') {
                            return new Date(a.additionalProperties['extra_order_date']).getTime() - new Date(b.additionalProperties['extra_order_date']).getTime();
                        } else {
                            return new Date(a.modified).getTime() - new Date(b.modified).getTime();
                        }
					});
				} else {
					this.documents = this.documents.sort(function(a, b) {
                        if (me.selectedNode.displayType === 'listDetail') {
                            return new Date(b.additionalProperties['extra_order_date']).getTime() - new Date(a.additionalProperties['extra_order_date']).getTime();
                        } else {
						    return new Date(b.modified).getTime() - new Date(a.modified).getTime();
                        }                     
					});
				}
			}
		},
		updateDocumentConfig() {
			if (!this.$store.state.nitida.documentConfig) return;

			this.documentConfig = JSON.parse(JSON.stringify(this.$store.state.nitida.documentConfig));
			this.updateDocumentCategory(this.documentConfig);
			this.completeInitUI();
		},
		updateDocumentCategory(category) {
			category.notifications = this.getCategoryBadges(category);
			if (!category.children) return;
			for (let child of category.children) {
				this.updateDocumentCategory(child);
			}
		},
		getCategoryBadges: function (category) {
			let allNotifications = this.$store.state.nitida.notifications;
			if (!allNotifications) return 0;

			let catNotifications= allNotifications.find(cat => cat.displayPath == category.displayPath);
			if (!catNotifications || !catNotifications.items) return 0;

			return catNotifications.items.length;
		},
		completeInitUI() {
			if (this.initCompleted==true) return;
			
			this.$nextTick(() => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const docSection = urlParams.get('docSection')
                console.log('QUERY STRING: ' + docSection);

                if(docSection){
                    if(docSection==="ADMINISTRACION"){
                        $(`#prod-sist-doc-${this.documentConfig.children[1].children[1].id}`).collapse('show');
                    } else if(docSection==="SAT") {
                        $(`#prod-sist-doc-${this.documentConfig.children[2].children[0].id}`).collapse('show');
                    } else {
                        $(`#prod-sist-doc-${this.documentConfig.children[0].children[0].id}`).collapse('show');
                    }
                } else {
                    $(`#prod-sist-doc-${this.documentConfig.children[0].children[0].id}`).collapse('show');
                }
                
				if (!this.isMobile()) {
                    if(docSection){
                        if (docSection==="ADMINISTRACION"){
                            this.selectNode(this.documentConfig.children[1].children[1].children[0]);
                        } else if(docSection==="SAT") {
                            this.selectNode(this.documentConfig.children[2].children[0].children[0]);
                        } else {
                            this.selectNode(this.documentConfig.children[0].children[0].children[0]);
                        }
                    } else {
                        this.selectNode(this.documentConfig.children[0].children[0].children[0]);
                    }
				}

				this.openNotificationCategory(this.documentConfig);
			});
			this.initCompleted=true;
		},
		openNotificationCategory(category) {
			if (!category.children) return;
			let shouldBeOpen = false;
			for (let child of category.children) {
				if (child.notifications>0) {
					shouldBeOpen = true;
				}
				this.openNotificationCategory(child);
			}
			if (shouldBeOpen) {
				$(`#prod-sist-doc-${category.id}`).collapse('show');
			}
		},
  }, 
    watch: {
        '$store.state.nitida.notifications': function() {
            if (this.$store.state.nitida.notifications && this.selectedNode) {
                let allNotifications = this.$store.state.nitida.notifications;
                let currentCatId = this.selectedNode.id;
                if (currentCatId.indexOf(':')>0) {
                    currentCatId = currentCatId.split(':')[1];
                }
                this.notifications = allNotifications.find(cat => cat.id == currentCatId);
                this.doFilter();
                this.updateDocumentConfig();
            }
        },
        '$store.state.nitida.detailNavigation': function() {
            if (!this.$store.state.nitida.detailNavigation.show && this.selectedNode) {
                this.selectedNode = null;
            }
        },
        '$store.state.nitida.documentConfig': function() {
            this.updateDocumentConfig();
        },
		'$store.state.nitida.currentClient': function() {
            if (this.selectedNode == null) {
                return;
            }

            if (this.selectedNode.id.includes(':')) {
                var node = this.documentConfig.children[0].children[0].children[0];
                this.selectNode(node);
                this.$refs.aside.scrollTo(0, 0);
            }

            this.selectNode(this.selectedNode);
		}        
	}, 
};
</script>

<style>
	/*** Documentacion ***/
	.DocAsideListContainer {
			width: 330px;
			max-width: 100%;
			margin-top: 20px;
			height: calc(100vh - 175px);
			overflow: auto;
			border-right: 1px solid #E2E6EA;
			grid-area: sidebar;
	}
	.DocAsideListContainer button {
			font-size: 0.875rem;
			color: #009CBD;
			justify-content: space-between;
			align-items: center;
			padding-left: 0;
			width: 100%;
			text-align: left;
	}
	.DocAsideListContainer button span {
			text-align: left;
			overflow: hidden;
			display: initial;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
	}
	.DocAsideListContainer button:hover {
			color: #5E6F82;
	}
	.DocAsideListContainer button:focus {
			box-shadow: none !important;
	}
	.DocAsideListContainer li a {
			font-size: 0.813rem;
			background-color: transparent;
			color: #343A40;
			padding: 5px 10px;
			border-radius: 0;
			line-height: 1rem;
	}
	.DocAsideListContainer li a:hover,
	.DocAsideListContainer li a.active {
			color: #000000;
			font-weight: 500;
	}
	.DocAsideListContainer h3 {
			font-size: 0.9rem;
			font-weight: 600;
			text-transform: uppercase;
			color: #5E6F82;
			margin: 10px 0;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
            height: 25px;
	}
	.DocAsideListContainer ul h3 i {
			margin-right: 8px;
	}
	.doc-type-list {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
	}
	.main-docs-section {
			width: 100%;
			overflow: auto;
			overflow-x: hidden;
			height: calc(100vh - 200px);
			padding-bottom: 30px;
			margin-right: -15px;
			margin-top: 35px;
	}
	.doc-section-title {
			font-size: 1.25rem;
			font-weight: 500;
			margin-bottom: 30px;
	}
	.catalog-item-container {
			text-align: center;
	}
    .catalog-image-container {
            width: 100%;
            height: 200px;
            max-width: 250px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border: 1px solid rgba(0, 0, 0, 0.125);
            margin: 0 auto;
    }
	.catalog-item-container h2 {
			font-size: 1.25rem;
			font-weight: 300;
			margin: 10px 0;
			color: #5E6F82;
			text-transform: uppercase;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
	}
	.download-btn.download-title {
			text-decoration: underline !important;
	}
	.download-btn.download-title:hover {
			color: #009CBD !important;
			text-decoration: underline;
	}
	.new-doc-list-bubble {
			width: 24px;
			height: 22px;
			background-color: #DC3545;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #FFFFFF;
			font-weight: 500;
	}
    .new-doc-list-bubble.bubble-menu {
        float: right;
        font-size: 0.813rem;
    }
	button:not(.collapsed) i.transform-icon:before {
			transform: rotate(0deg) !important;
			content: "\f077" !important;
	}
	.catalog-item-container .doc-date {
			font-size: 0.875rem;
			color: #009CBD;
			margin-bottom: 0.3rem;
	}
    .btn-group .btn-outline-primary {
            color: #009CBD;
            border-color: #009CBD;
    }
    .btn-group .btn-outline-primary:hover {
            background-color: #009CBD;
            color: #FFFFFF;
    }
    .btn-link-filter {
           color: #5E6F82;
           padding: 5px;
           font-size: 0.875rem;
           border: none;
           background-color: transparent;
    }
    .btn-link-filter.active {
           color: #009CBD;
    }
    .btn-link-filter:hover {
           background-color: #f3f3f3;
           color: #009CBD;
    }
	@media only screen and (max-width: 768px) {
	.DocAsideListContainer {
			width: 100%;
			height: auto;
			overflow: hidden;
			border-right: none;
			margin-top: 0;
	}
	.main-docs-section {
			height: 100%;
            margin-top: 0;
	}
	.doc-section {
			padding-left: 0;
			padding-right: 0;
			margin-top: 0;
	}
	.doc-title {
			font-size: 1rem;
	}
	.doc-date {
			font-size: 0.813rem;
			color: #009CBD;
			margin-bottom: 0;
	}
	.doc-list-phone {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #009CBD;
	}
    .doc-list-phone-alt {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #009CBD;
            position: absolute;
            top: 28px;
            right: 10px;
	}
	.doc-list-phone i {
			margin-left: 15px;
	}
	.new-doc {
			width: auto;
			height: 20px;
			border-radius: 30px;
			background-color: #DC3545;
			color: #FFFFFF;
			font-size: 0.7rem;
			padding: 5px 15px;
			display: flex;
			align-items: center;
	}
    .new-doc-list-bubble.bubble-menu {
        position: relative;
        bottom: 40px;
        left: 40px;
        width: 20px;
        height: 20px;
        font-size: 0.813rem;
    }
	.catalog-item-container {
			border-bottom: 1px solid #E2E6EA;
			padding-bottom: 20px;
			padding-top: 20px;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
</style>