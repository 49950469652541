<template>
  <h2 class="Headline dateToday" v-html="formatFullDate()"></h2>
</template>

<script>
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: 'DateToday',
  mixins: [BaseComponent],
};
</script>

<style>
    h2.Headline.dateToday:first-letter {
        text-transform: capitalize;
    }
</style>
