import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App';
import router from './Router.js';
import Keycloak from 'keycloak-js';
import store from './store';
import VueLogger from 'vuejs3-logger';
import getBrowserLocale from "@/components/util/get-browser-locale";

import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";

const isProduction = process.env.NODE_ENV === 'production';

// **********************************************************

const loggerOptions = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

// **********************************************************

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  const supportedLocales = ['es','en'];
  if (supportedLocales.includes(browserLocale)) {
    return browserLocale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
}

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

export const i18n = new createI18n({
  locale: getStartingLocale(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})

// **********************************************************

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueLogger, loggerOptions);
window.$vue = app;
window.$store = store;
function initVue() {
	app.mount('#app');
}

// **********************************************************
const keycloak = Keycloak(process.env.VUE_APP_MGNL_STATIC+'/keycloak.json');

window.kc_profile = new Object();

// eslint-disable-next-line no-unused-vars
window.kc_logout = function () {
  keycloak.logout();
}

function kc_loadProfile() {
	keycloak.loadUserProfile().success(function(profile) {
	app.$log.info(profile.username);
    window.kc_profile = profile;
    window.$store.commit('nitida/setProfile', profile);
	}).error(function() {
		console.log('Failed to load profile');
		setTimeout(kc_loadProfile, 3000);
	});
}

if (keycloak.authenticated) {
    initVue();
} else {
  keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).success((auth) => {
    if (!auth) {
      window.location.reload();
      return;
    } else {
      app.$log.info("Authenticated");
    }

    localStorage.setItem("vue-token", keycloak.token);
    localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

    if (!window.vue)
        initVue();

    kc_loadProfile();

    setInterval(() =>{
      keycloak.updateToken(70).success((refreshed)=>{
        if (refreshed) {
          app.$log.debug('Token refreshed'+ refreshed);
          localStorage.setItem("vue-token", keycloak.token);
        } else {
          app.$log.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).error(()=>{
			app.$log.error('Failed to refresh token');
      });


    }, 60000)

  }).error(() =>{
    app.$log.error("Authenticated Failed");
  });
}

