export default {
	downloadDocument(fileName, blob) {
		var link = document.createElement('a');

		//Soporte IE
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileName);
			return;
		}

		var url= window.URL.createObjectURL(blob);
		link.href = url;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();

		setTimeout(function() {
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		}, 1000);
  }
}