<template>
  <div class="modal modalNews" tabindex="-1" id="newsModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
      <div class="modal-content">
				<div class="modal-header">
					<div>
						<h4 class="modal-title">{{ item ? item['title'] : '???' }}</h4>
						<span class="date-text">{{ item ? formatDate(item['publishedDate']) : '???' }}</span>
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="ImageNewsDetail" :style="getBackgroundImage(item)" />
					<span class="contentNewsDetail" v-html="item ? item['content'] : '???'" />
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary btn-flat" data-bs-dismiss="modal">{{ $t('common.close') }}</button>
				</div>
			</div>
    </div>
  </div>
</template>
 
<script>
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: 'NewsDetail',
	props: ['item'],
	mixins: [BaseComponent]  
}
</script>

<style>
/*** Modal News ***/
.modalNews .modal-header {
    border-bottom: none;
    padding: 1rem 1rem 0.5rem;
}
.modalNews .modal-header h4 {
    color: #343A40;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1;
}
.date-text {
    color: #009CBD;
    font-size: 0.75rem;
}
.ImageNewsDetail {
    width: 100%; 
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
    border-radius: 5px;
}
.contentNewsDetail {
    color: #343A40;
    font-size: 0.875rem;
}
.modal-footer .btn-flat {
    text-transform: uppercase;
    font-size: 0.938rem;
    color: #000000;
    background-color: transparent;
    border: none;
}
</style>
