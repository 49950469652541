<template>
<div>
  <div class="modal modalCounselingForm" tabindex="-1" id="counselingFormModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ $t('counselingForm.modal.title') }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!--<form>-->
                    <ul>
                        <li>
                            <label for="msg">{{ $t('counselingForm.modal.msg') }} </label>
                            <textarea v-model="message" id="msg" name="user_message" required></textarea>
                        </li>
                        <li>
                            <button class="btn btn-principal" @click="sendEmail()">{{ $t('common.send') }}</button>
                        </li>                            
                    </ul>
                <!--</form>-->                  
            </div>		
        </div>
    </div>
  </div>
  <div class="modal modalCounselingForm" tabindex="-1" id="successForm" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
            <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" style="margin: 0;">
                <i class="bi-check-circle-fill"></i>
                <strong class="mx-2">{{ $t('counselingForm.modal.success1') }}</strong> {{ $t('counselingForm.modal.success2') }}
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>            
        </div>
    </div>               
  </div>
  <div class="modal modalCounselingForm" tabindex="-1" id="errorForm" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
            <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" style="margin: 0;">
                <i class="bi-exclamation-octagon-fill"></i>
                <strong class="mx-2">{{ $t('counselingForm.modal.error-1') }} </strong> {{ $t('counselingForm.modal.error-2') }}
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>            
        </div>
    </div>               
  </div>  
  <!-- Spinner -->
    <!-- DIV -->
    <div class="loading-container" id="spinner" style="display:none;">
        <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">{{ $t('common.loading') }}</span>
        </div>
    </div>  
</div>
</template>
 
<script>
import api from "../api/nitida.js";
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: 'CounselingForm',
 	data() {
    return {
			message: null,
            msg: null
    };
	},
	mounted() {
		$('#counselingFormModal').on('show.bs.modal', function (event) {
			$('#msg').val('');
		});
        $('#spinner').hide(); 
	},
	methods: {
		sendEmail() {
		 	if (!this.message || this.message.trim().length === 0) {
			 	return $('#msg').addClass('invalid');
		 	}

			const data = {
				message: this.message.replaceAll('\n', '<br>')
			};

            $('#spinner').show();
			api.sendContactMail(data,
			() => {			
                $('#spinner').hide();	
				$('#counselingFormModal').modal('hide');
				$('#successForm').modal('show');
				const timeoutId = setTimeout(() => {
					clearTimeout(timeoutId);
					$('#successForm').modal('hide');
				}, 5000);
			},
			(err) => {
        console.log(err);
                $('#spinner').hide();
				$('#counselingFormModal').modal('hide');
				$('#errorForm').modal('show');
				const timeoutId = setTimeout(() => {
					clearTimeout(timeoutId);
					$('#errorForm').modal('hide');
				}, 5000);               
			});
	 	}
 	}    
}
</script>

<style>
/*** Modal Asesoramiento ***/
.modalCounselingForm .modal-header {
    border-bottom: none;
    padding: 1rem 1rem 0.5rem;
}
.modalCounselingForm .modal-header h4 {
    color: #343A40;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1;
}
.date-text {
    color: #009CBD;
    font-size: 0.75rem;
}
.modal-footer .btn-flat {
    text-transform: uppercase;
    font-size: 0.938rem;
    color: #000000;
    background-color: transparent;
    border: none;
}
textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #afb8c1;
  vertical-align: top;
  height: 15em;  
}
.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}
.alert i[class^="bi-"]{
  font-size: 1.5em;
  line-height: 1;
}
.invalid {
  background-color: #009dbd31;
}
</style>
