<template>
<div>
  <div class="modal modalNewIncidences" tabindex="-1" id="incidencesNewModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
      <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('sat.new.title') }}</h4>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeNewIncidenceModal()"></button>
                </div>
                <div class="modal-body">
                    <form autocomplete="off">
                        <div class="container p-1 px-md-4">
                            <div class="formField">
                                <label for="incidences">{{ $t('sat.new.type') }} </label><br>
                                <select v-model="optionIndex" name="incidences" id="incidences">
                                    <option v-for="(option, index) in options" :key="index" :value="index">{{ option.text }}</option>
                                </select>
                            </div>
                            <div class="incidenceLegend" v-if="optionIndex==0">
                                <span v-html="legendClaim"></span>
                            </div>     
                            <div class="incidenceLegend" v-if="optionIndex==1">
                                <span v-html="legendInfo"></span>
                            </div>
                            <div class="incidenceLegend" v-if="optionIndex==2">
                                <span v-html="legendSuggestion"></span>
                            </div>                                                                               
                            <div class="formField" style="margin-bottom: 4em;">
                                <label for="incidenceDescription">{{ $t('sat.new.description') }}</label><br>
                                <textarea v-model="description" id="incidenceDescription" name="incidenceDescription" maxlength="500" required></textarea>
                                <div id="counter"></div>
                            </div>
                            <div class="incidenceLegend">
                                <span v-html="legendFiles"></span>
                            </div>                          
                            <!--<div v-if="optionIndex==1">
                                <div class="d-flex justify-content-between align-items-center related-articles-container">
                                    <h4>{{ $t('sat.new.incidenceRelatedArticles') }}</h4>
                                    <button type="button" class="btn btn-primary" data-bs-target="#addArticleIncidenceModal" @click="showAddArticleModal()">
                                     <i class="fa-solid fa-circle-plus"></i>
                                     {{ $t('common.add') }}
                                    </button>                                   
                                </div>
                                <div class="list-group new-incidence-list">
                                    <div v-for="(article, index) in articles" :key="index" class="list-group-item list-group-item-action" aria-current="true">
                                       <small class="new-incidence-list-code">{{article.artCode}}</small>
                                       <div class="d-flex w-100 justify-content-between">
                                           <h5 class="mb-1 new-incidence-list-lot-number">
                                             {{article.artNumber}}
                                            </h5>
                                           <div class="d-flex justify-content-between align-items-center">
                                                <small class="me-3 new-incidence-list-quantity">
                                                    {{article.artQuantity}}
                                                </small>
                                               <button type="button" @click="deleteArticle(index)"><i class="fa-solid fa-trash-can"></i></button>
                                           </div>
                                       </div>
                                       <p class="mb-1 new-incidence-list-description">
                                          {{article.artDescription}}
                                        </p> 
                                       <p class="mb-1 new-incidence-list-description">
                                          {{article.salesDocument}}
                                        </p>                                                                                
                                    </div>
                                </div>

                            </div>-->                                                 
                            <div class="formField" style="margin-top: 10px;">
                                <label class="mb-1">{{ $t('sat.new.attachments') }}</label><br>
                                <input type="file" id="newIncidenceFiles" name="newIncidenceFiles" multiple autocomplete="false" @change="handleFileUpload( $event )"/>
                            </div>
                        </div>
                    </form>             
                </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-flat" style="color: #009CBD;" @click="createIncidence()">{{ $t('common.create') }}</button>
                <button type="button" class="btn btn-secondary btn-flat" @click="closeNewIncidenceModal()">{{ $t('common.close') }}</button>
            </div>
      </div>
    </div>
  </div>
  <!--- Modal Añadir articulo -->
  <div class="modal" id="addArticleIncidenceModal" tabindex="-1" aria-labelledby="addArticleIncidenceModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="addArticleIncidenceModalLabel">{{ $t('sat.add.article.title') }}</h4>
                <button type="button" class="btn-close" aria-label="Close" @click="closeAddArticleModal()"></button>
            </div>
            <div class="modal-body">
                <form autocomplete="off">
                    <div class="container p-1 px-md-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="formField mt-0">
                                    <label for="articleCode">{{ $t('sat.add.articleCode') }}</label><br>
                                    <input v-model="articleCode" type="text" id="articleCode" name="articleCode" autocomplete="false" required>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="formField mt-0">
                                    <label for="articleDescription">{{ $t('sat.add.articleDescription') }}</label><br>
                                    <textarea v-model="articleDescription" id="articleDescription" name="articleDescription" autocomplete="false" required></textarea>                        
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="formField mt-0">
                                    <label for="articleQuantity">{{ $t('sat.add.articleQuantity') }}</label><br>
                                    <input v-model="articleQuantity" type="text" id="articleQuantity" name="articleQuantity" autocomplete="false" required>                         
                                </div>
                            </div>
                            <div class="col-12 col-md-8">
                                <div class="formField mt-0">
                                    <label for="articleNumber">{{ $t('sat.add.articleNumber') }}</label><br>
                                    <input v-model="articleNumber" type="text" id="articleNumber" name="articleNumber" autocomplete="false">                         
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="formField mt-0">
                                    <label for="salesDocument">{{ $t('sat.add.salesDocument') }}</label><br>
                                    <input v-model="salesDocument" type="text" id="salesDocument" name="salesDocument" autocomplete="false">                         
                                </div> 
                            </div>                   
                        </div>           
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-flat" style="color: #009CBD;" data-bs-target="#incidencesNewModal" @click="addArticle()">{{ $t('common.add') }}</button>
                <button type="button" class="btn btn-secondary btn-flat" @click="closeAddArticleModal()">{{ $t('common.cancel') }}</button>
            </div>
        </div>
    </div>
  </div> 
  <!--- Popups gestión errores -->  
  <div class="modal modalNewIncidenceForm" tabindex="-1" id="successForm" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
            <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" style="margin: 0;">
                <i class="bi-check-circle-fill"></i>
                <strong class="mx-2">{{ $t('sat.new.incidence.modal.success') }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>            
        </div>
    </div>               
  </div>
  <div class="modal modalNewIncidenceForm" tabindex="-1" id="errorForm" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
        <div class="modal-content">
            <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" style="margin: 0;">
                <i class="bi-exclamation-octagon-fill"></i>
                <strong class="mx-2">{{ $t('sat.new.incidence.modal.error-1') }} </strong> {{ $t('sat.new.incidence.modal.error-2') }}
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>            
        </div>
    </div>               
  </div>
  <!-- Spinner -->
    <!-- DIV -->
    <div class="loading-container" id="spinner" style="display:none;">
        <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">{{ $t('common.loading') }}</span>
        </div>
    </div> 
</div>
</template>
 
<script>
import api from "../api/nitida.js";
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: 'IncidencesNew',
  props: ["legendClaim", "legendInfo", "legendSuggestion", "legendFiles"],   
    data() {
    return {
            files: [],
            optionIndex: 0,
            description: null,
            salesDocument: null,
            newIncidenceFiles: null,
            articles: [],
            articleCode: null,
            articleDescription: null,
            articleQuantity: null,
            articleNumber: null,
            options: [ 
                { type: 'Reclamación', text: this.$t('sat.new.type-1') },
                { type: 'Petición información', text: this.$t('sat.new.type-0') },
                { type: 'Sugerencia', text: this.$t('sat.new.type-2') },
            ],
    };
  },
    mounted() {
        $('#incidencesNewModal').on('show.bs.modal', function (event) {
            $('#incidences').val('0');
            $('#incidenceDescription').val('');
            $('#newIncidenceFiles').val('');
        });
        $('#successForm').modal('hide');
        $('#errorForm').modal('hide');
        $('#spinner').hide();  
        this.countChar();      
    },
    methods: {
        handleFileUpload(event){
             this.files = event.target.files;
        },
        show() {
            $('#incidencesNewModal').modal('show');
        },      
        closeNewIncidenceModal() {
            $('#incidencesNewModal').modal('hide');
        },
        showAddArticleModal() {
            $('#incidencesNewModal').modal('hide');
            $('#articleCode').val('');
            this.articleCode=null;
            $('#articleDescription').val('');
            this.articleDescription=null;            
            $('#articleQuantity').val('');
            this.articleQuantity=null;            
            $('#articleNumber').val('');
            this.articleNumber=null;              
            $('#salesDocument').val('');   
            this.salesDocument=null;                     
            $('#addArticleIncidenceModal').modal('show');             
        },
        closeAddArticleModal() {
            $('#addArticleIncidenceModal').modal('hide');
            $('#incidencesNewModal').modal('show');
        },
        addArticle() {
            //1. Recogida y validación de datos del formulario
            const artCode = this.articleCode;           
            const artDescription = this.articleDescription;             
            const artQuantity = this.articleQuantity;             
            const artNumber = this.articleNumber;
            const salesDocument = this.salesDocument;                                          

            if (!artCode || artCode.trim().length === 0) {
                return $('#articleCode').addClass('invalid');
            }
            if (!artDescription || artDescription.trim().length === 0) {
                return $('#articleDescription').addClass('invalid');
            }
            if (!artQuantity || artQuantity.trim().length === 0) {
                return $('#articleQuantity').addClass('invalid');
            }
            /*if (!artNumber || artNumber.trim().length === 0) {
                return $('#articleNumber').addClass('invalid');
            }*/    
            /*if (!salesDocument || salesDocument.trim().length === 0) {
                return $('#salesDocument').addClass('invalid');
            }*/             

            console.log('AC: '+artCode);
            console.log('AD: '+artDescription);
            console.log('AQ: '+artQuantity);
            console.log('AN: '+artNumber); 
            console.log('salesDocument: '+salesDocument);                                       

            //2. añadir a la lista
            const article = {
                artCode: this.articleCode,
                artDescription: this.articleDescription,
                artQuantity: this.articleQuantity,
                artNumber: this.articleNumber,
                salesDocument: this.salesDocument
            };

            this.articles.push(article);

            this.closeAddArticleModal();
        },
        deleteArticle(index) {
            this.articles.splice(index, 1);
        },
        createIncidence() {
            let description = '';
            let description2 = '';

            if (!this.description || this.description.trim().length === 0) {
                return $('#incidenceDescription').addClass('invalid');
            }           

            //Si el tipo de incidencia, es 'Reclamación', comprobamos que las lista de artículos no esté vacía
            /*if (this.options[this.optionIndex].type == 'Reclamación' && this.articles.length == 0) {
                return $('#noArticlesValidator').show();
            }*/
                
            /*let description2 = '';

            if (this.options[this.optionIndex].type == 'Reclamación') {

                if(this.articles.length !== 0) {
                    description2 = this.convertProductListToString();
                }
                
                console.log('Descripción 2: '+description2);
            }*/    
 
            let formData = new FormData();
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('file[' + i + ']', file);
            }

            description = this.description.substring(0,250).split("\n").join("");
            description2 = this.description.substring(250,500).split("\n").join(""); 

            console.log('Descripción: ' + description);
            console.log('Descripción 2: ' + description2);             

            formData.set('type',this.options[this.optionIndex].type);
            formData.set('description', description);
            formData.set('description2', description2); 

            $('#spinner').show();
            api.createIncidence(formData,
            () => {  
                $('#spinner').hide();                                   
                $('#incidencesNewModal').modal('hide');               
                $('#successForm').modal('show');
                const timeoutId = setTimeout(() => {
                    clearTimeout(timeoutId);
                    $('#successForm').modal('hide');
                }, 5000);    
                api.getIncidences();
            },
            (err) => {
                $('#spinner').hide();                
                $('#incidencesNewModal').modal('hide');           
                $('#errorForm').modal('show');
                const timeoutId = setTimeout(() => {
                    clearTimeout(timeoutId);
                    $('#errorForm').modal('hide');
                }, 5000);                      
            });
        },
        countChar() {
            const messageEle = document.getElementById('incidenceDescription');
            const counterEle = document.getElementById('counter');

            messageEle.addEventListener('input', function (e) {
                const target = e.target;

                // Get the `maxlength` attribute
                const maxLength = target.getAttribute('maxlength');

                // Count the current number of characters
                const currentLength = target.value.length;

                counterEle.innerHTML = `${currentLength}/${maxLength}`;
            });
        },         
        /*convertProductListToString() {
            if (this.optionIndex == 0) {
                return null;
            }

            let text = '';
            
            for (let i = 0; i < this.articles.length; i++) {
                const article = this.articles[i];

                const pos = i + 1;

                text += '   Producto ' + pos + ': ' + article.artCode + ' - ';
                text += 'Descripcion ' + pos + ': ' + article.artDescription + ' - ';
                text += 'Cantidad ' + pos + ': ' + article.artQuantity + ' - ';
                text += 'Lote ' + pos + ': ' + article.artNumber + ' - ';
                text += 'Doc. Venta ' + pos + ': ' + article.salesDocument;
                text += ' ';
            }

            return text;
        }*/         
  }     
}
</script>

<style>
.modalNewIncidenceForm .modal-header {
    border-bottom: none;
    padding: 1rem 1rem 0.5rem;
}
.modalNewIncidenceForm .modal-header h4 {
    color: #343A40;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1;
}
/*** Modal New Incidences ***/
.modalNewIncidences .modal-header,
#addArticleIncidenceModal .modal-header {
    border-bottom: none;
    padding: 1rem 1rem 0.5rem;
}
.modalNewIncidences .modal-header h4,
#addArticleIncidenceModal .modal-header h4 {
    color: #343A40;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1;
}
.date-text {
    color: #009CBD;
    font-size: 0.75rem;
}
.modal-footer .btn-flat {
    text-transform: uppercase;
    font-size: 0.938rem;
    color: #000000;
    background-color: transparent;
    border: none;
}
.formField{
    margin: 2em 0;
}
.formField label {
    font-size: 0.75rem;
}
textarea {
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid #afb8c1;
  vertical-align: top;
  height: 13em;  
}
.formField input,
input {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid #afb8c1;
    vertical-align: top;
    height: 2em;
}
input#newIncidenceFiles {
    font-size: 0.875rem;
}
select#incidences {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid #afb8c1;
    vertical-align: top;
    font-size: 0.875rem;
}
select#incidences:focus-visible,
textarea:focus-visible,
input:focus-visible {
    outline: none;
}
.custom-file-upload {
    background-color: #009CBD !important;
    color: #FFFFFF !important;
    border-radius: 30px !important;
    border: none;
    padding: 6px 15px !important;
    font-size: 0.875rem !important;
    min-width: 100px;
    cursor: pointer;
}
.related-articles-container {
    border-bottom: 1px solid #E0E0E0;
}
.related-articles-container h4 {
    font-size: 0.875rem;
    font-weight: 400;
    color: #000000;
}
.related-articles-container button {
    background-color: transparent !important;
    border: none !important;
    color: #009CBD;
}
.related-articles-container button:hover {
    background-color: transparent !important;
    border: none !important;
    color: #009CBD;
}
.new-incidence-list button {
    background-color: transparent;
    border: none;
    color: #DC3545;
}
.new-incidence-list .list-group-item {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    cursor: auto;
}
.new-incidence-list .list-group-item-action:hover, 
.new-incidence-list .list-group-item-action:focus {
    background-color: transparent;
}
.new-incidence-list-code {
    font-size: 0.75rem;
    color: #009CBD;
}
.new-incidence-list-lot-number {
    font-size: 0.938rem;
    color: #000000;
    font-weight: 400;
}
.new-incidence-list-description {
    font-size: 0.813rem;
    color: #5E6F82;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 80%;
}

textarea:valid, input:valid {
 background-color: rgb(255, 255, 255);    
}
.invalid {
  background-color: #009dbd31;
}

div.incidenceLegend {
    font-size: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgb(0 156 189);
    border-radius: 0.25rem;
    padding: 10px;
}

div#counter {
    font-size: 12px;
    text-align: left;
}

p {
    margin-bottom: 0px;
}

</style>
