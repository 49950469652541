<template>
  <div class="col-md-6"
  style="padding: 0 10px;" v-if="inEditor || (showRoles && showRoles.includes(userInfo.title))">
    <div class="fill" v-bind:style="'margin-bottom: 0; background-image: url('+`${dam_raw}${image['@link']}`+');'"></div>
   <!--<img class="Image" v-bind:src="`${dam_raw}${image['@link']}`" alt="Etiam 
  Purus" style="width: 100%;"/>-->
  </div>
</template>

<script>
import { EditorContextHelper } from '@magnolia/vue-editor';

export default {
  name: "Image",
  props: ["image", "showRoles"],
  data() {
    return {
      dam_raw: process.env.VUE_APP_MGNL_DAM_RAW,
    }
  },
  computed: {
    userInfo() {
        return this.$store.state.nitida.userInfo;
    },  
    inEditor() {
        return (EditorContextHelper.inIframe() || EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview());
    }
  },  
};
</script>

<style>

</style>