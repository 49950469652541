<template>
  <h2 class="Headline">{{ text }}</h2>
</template>

<script>
export default {
  name: 'Headline',
  props: ['text'],
};
</script>
