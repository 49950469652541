// initial state
const state = {
	profile: null,
	areas: [],
	currentArea: null,
	news: [],
	notifications: [],
  incidences: null,
	clients: [],
	currentClient: null,
  documentConfig: null,
	userInfo: {},
	detailNavigation: {
		title: '',
		show: false
	}
}

// getters
const getters = {
}

// actions
const actions = {
}

// mutations
const mutations = {
	setNotifications(state, notifications) {
		state.notifications = notifications;
	},
	setDocumentConfig(state, documentConfig) {
		state.documentConfig = documentConfig;
	},
	setProfile(state, profile) {
		state.profile = profile;
	},
	setAreas(state, areas) {
		state.areas = areas;
	},
	setClients(state, clients) {
		state.clients = clients;
	},
	setCurrentClient(state, client) {
		state.currentClient = client;
	},
	setNews(state, news) {
		state.news = news;
	},
	setCurrentArea(state, area) {
		state.currentArea = area;
	},
	setIncidences(state, incidences) {
		state.incidences = incidences;
	},    
	setUserInfo(state, userInfo) {
		state.userInfo = userInfo;
	}, 
	setDetailNavigation(state, detailNavigation) {
		state.detailNavigation = detailNavigation;
	},  
}
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}