<template>
  <div class="container-fluid FeaturedNews">
    <EditableArea
      v-if="items"
      v-bind:content="items"
      v-bind:parentTemplateId="metadata['mgnl:template']"
      class="featuredNews-container"
    />
  </div>
</template>

<script>
import { EditableArea } from '@magnolia/vue-editor';

export default {
  name: "FeaturedNews",
  components: {
    EditableArea
  },
  props: ["items", "metadata"]
};


</script>

<style>

</style>