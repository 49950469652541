<template>
    <div>
        <div class="container-fluid mt-4 d-none d-md-block">
            <div class="row">
            <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{ $t('sat.table.openDate') }}</th>
                    <th scope="col">{{ $t('sat.table.closeDate') }}</th>
                    <th scope="col">{{ $t('sat.table.incidenceCode') }}</th>
                    <th scope="col">{{ $t('sat.table.incidenceType') }}</th>
                    <th scope="col">{{ $t('sat.table.incidenceDescription') }}</th>
                    <th scope="col">{{ $t('sat.table.incidenceState') }}</th>                        
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in incidences" :key="item['@id']" 
                    href="#incidencesModal" data-bs-toggle="modal" 
                    @click="setCurrentItem(item)">
                    <td class="incidence-open-date-column align-middle" scope="row">{{ item['openDate'] ? formatDate(item['openDate']) : '' }}</td>
                    <td class="incidence-date-column align-middle">{{ item['closeDate'] && formatDate(item['closeDate']) != null ? formatDate(item['closeDate']) : '----' }}</td>
                    <td class="incidence-code-column align-middle">{{ item['incidenceCode'] ? item['incidenceCode'] : ''}}</td>
                    <td class="incidence-type-column align-middle">{{ item['incidenceType'] ? item['incidenceType'] : ''}}</td>
                    <td class="align-middle">{{ item['incidenceDescription'] ? item['incidenceDescription'] : ''}}</td>
                    <td class="align-middle">{{ item['incidenceState'] ? item['incidenceState'] : ''}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        </div>
        <!-- Incidences phone -->
        <div class="container-fluid d-block d-md-none p-0">
            <h2 class="page-title-phone">
                <i class="fa-solid fa-circle-exclamation"></i>
                {{ $t('sat.phone.title') }}
            </h2> 
            <div class="row">
                <div class="list-group px-0" v-for="item in incidences" :key="item['@id']">
                    <a href="#incidencesModal" data-bs-toggle="modal"
                    class="list-group-item list-group-item-action" aria-current="true" 
                    @click="setCurrentItem(item)">
                        <div class="d-flex justify-content-between align-items-center">
                          <small class="incidence-date">{{ formatDate(item['openDate']) }}</small>
                        <small style="font-size: 0.75rem;" v-if="item['closeDate']">{{ item['closeDate'] && formatDate(item['closeDate']) != null ? formatDate(item['closeDate']) : '----' }}</small>
                        </div>
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="incidence-title mb-1">{{ item['incidenceCode'] ? item['incidenceCode'] : ''}}</h5>
                            <span class="incidence-status">{{ item['incidenceState'] ? item['incidenceState'] : ''}}</span>
                        </div>
                        <p class="incidence-description mb-1">{{ item['incidenceDescription'] ? item['incidenceDescription'] : ''}}</p>
                    </a>
                </div>
            </div>       
        </div>
        <div class="fixed-action-btn">
            <button type="button" class="fab-button" href="#incidencesNewModal" @click="$refs.incidenceNew.show()">+</button>
        </div>     
        <IncidencesNew ref="incidenceNew" :legendClaim="legendClaim" :legendInfo="legendInfo" :legendSuggestion="legendSuggestion" :legendFiles="legendFiles"/>    
        <IncidencesDetail :item="currentItem" />         
    </div>
</template>

<script>
import IncidencesDetail from "./IncidencesDetail";
import IncidencesNew from "./IncidencesNew";
import BaseComponent from "./common/BaseComponent.js";

export default {
  name: "IncidencesMain",
  props: ["legendClaim", "legendInfo", "legendSuggestion", "legendFiles"],   
  mixins: [BaseComponent],
  components: {
    IncidencesDetail,
    IncidencesNew
  },  
  data() {
    return {
        currentItem: null,
    }
  },
  computed: {
    incidences() {
        return this.$store.state.nitida.incidences;
    }
  },
  methods: {
      setCurrentItem(item) {
          console.log('item: '+JSON.stringify(item) );
          this.currentItem = item;
      }     
  },  
};
</script>
