<template>
 	<div class="container-fluid p-0 mt-4">
		<div class="row g-3">
			<div class="col-12 col-sm-6 col-lg-12" v-for="item in news" :key="item['@id']">
				<div class="container-fluid newsList">
					<div class="row g-3">
						<div class="col-lg-2">
							<a href="#newsModal" data-bs-toggle="modal" @click="currentItem = item">
								<div class="newsListImageContainer" :style="getBackgroundImage(item)" />
							</a>
						</div>
						<div class="col-lg-10">
							<a href="#newsModal" data-bs-toggle="modal" @click="currentItem = item">
								<h2>{{ item['title'] }}</h2>
							</a>
							<h3 >{{ formatDate(item['publishedDate']) }}</h3>
							<p>{{ item['resume'] }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
    <NewsDetail :item="currentItem" />
	</div>
</template>
 
<script>
import BaseComponent from "./common/BaseComponent.js";
import NewsDetail from "./NewsDetail";

export default {
 	name: 'NewsList',
	mixins: [BaseComponent],
 	components: {
    NewsDetail
 	}, 
 	data() {
   	return {
		 	currentItem: null,
   	}
 	},
	computed: {
		news() {
			return this.$store.state.nitida.news;
		}
	},
 	methods: {
	},
}
</script>

<style>
</style>
