import { createStore } from 'vuex'
import nitida from './modules/nitida' 
//import createLogger from '../../../src/plugins/logger'
 
const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    nitida
  },
  strict: debug,
  //plugins: debug ? [createLogger()] : []
})