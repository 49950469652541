<template>
  
    <li class="ToolsItem" v-if="inEditor || (showRoles && showRoles.includes(userInfo.title))">
        <a :href="pageLink" class="tools-items" target="_blank">
        <div>
            <i v-bind:class="icon"></i>
            <span>{{ text }}</span> <!--{{ icon }}-->
        </div>
        <i class="fa-solid fa-chevron-right"></i>
        </a>
    </li>
  
</template>

<script>
import { EditorContextHelper } from '@magnolia/vue-editor';

export default {
  name: "ToolsItem",
  props: ["text", "icon", "pageLink", "showRoles"],

    computed: {
        userInfo() {
            return this.$store.state.nitida.userInfo;
        },
		inEditor() {
			return (EditorContextHelper.inIframe() || EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview());
		}
    },

};
</script>

<style>
</style>