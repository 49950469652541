
<template>
 	<div class="container-md mt-4 areasList">
		<h1 class="text-center">{{ title }}</h1>
		<h3 class="text-center">{{ subtitle }}</h3>
		<div class="row g-3">
			<div class="col-12 col-sm-6 col-lg-3" v-for="item in areas" :key="item['@id']">
				<a role="button" @click="goTo(item)">
					<div class="zoomin">
						<div class="areasListImageContainer zoom" :style="getBackgroundLinearImage(item)">
							<h2>{{ item['title'] }}</h2>
						</div>
					</div>
				</a>
			</div>
		</div>
    <button type="button" class="btn btn-principal" href="#counselingFormModal" data-bs-toggle="modal">{{ $t('areas.counselingFormModalButton.title') }}</button>
    <CounselingForm />    
  </div>
</template>
 
<script>
import BaseComponent from "./common/BaseComponent.js";
import CounselingForm from "./CounselingForm";

export default {
 	name: 'AreasList',
 	props: ['title', "subtitle"],
	mixins: [BaseComponent],  
    components: {
        CounselingForm,
    },     
 	data() {
   	return {
	 		dam_hostname: process.env.VUE_APP_MGNL_DAM_RAW,
   	}
 	},
	computed: {
		areas() {
			return this.$store.state.nitida.areas;
		}
	},
 	methods: {
		goTo(item) {
			console.log(`*** goto: [ path: '${item['areaLink']}' - area: ${item['@id']} ]`);
			this.$router.push({ path: item['areaLink'], query: { area: item['@id'] } });
		},
		getBackgroundLinearImage(item) {
			if (!item) {
				return '';
			}
			return `background: linear-gradient(${item['areaColorBackground']} 0, ${item['areaColorBackground']} 100%), url('${this.dam_hostname}${item.image.renditions ? item.image.renditions['480'].link : item.image.link}');`;
		}
	}
}
</script>

<style>

</style>
