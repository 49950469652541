<template>
  <div class="modal productDetail" tabindex="-1" id="productModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
      <div class="modal-content">
				<div class="modal-header">
					<div>
						<h4 class="modal-title">{{ item ? item['description'] : '???' }}</h4>
						<!--<span class="date-text">{{ item ? new Date(item['updateDate']).toLocaleDateString() : '???' }}</span>-->
					</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<img v-if="subfamilyType && subfamilyType === subfamilyType.EQUIPMENT_SUBFAMILY" class="img-fluid mx-auto d-block"
					style="height: 250px !important;"
					:src="getBackgroundImage()" :onerror="`this.src='${cdnRoot}/recursos/imagen-no-disponible.png'`">
					<span class="contentNewsDetail" v-html="item ? item['description2'] : '???'" />
					<div class="d-flex justify-content-start flex-wrap mt-4" v-if="item!==null">
						<a @click="downloadDocument(getProductTechniqueFileUrl())" style="cursor:pointer;" v-if="subfamily ? subfamily['subFamilyCode']['techniqueFile'] === 'true' : false">Ficha Técnica</a>
						<a @click="downloadDocument(getProductSecurityFileUrl())" style="cursor:pointer;" v-if="subfamily ? subfamily['subFamilyCode']['securityFile'] === 'true' : false">Ficha de Seguridad</a>
						<a @click="downloadDocument(getProductBiocidaRegisterNumberUrl())" style="cursor:pointer;" v-if="item ? item['biocidaRegisterNumber'] : false">Registro Biocida</a>
						<a @click="downloadDocument(getProductEcolabelRegisterNumberUrl())" style="cursor:pointer;" v-if="item ? item['ecolabelRegisterNumber'] : false">Registro Ecolabel</a>
					</div>
					<!--<hr>-->
					<div v-if="relatedItems && relatedItems.length > 0" class="container-fluid p-0">
						<div class="row products">
							<h2 class="m-0">Formatos</h2>
							<div v-for="product in relatedItems" :key="product['@id']" 
                                 v-bind:class="product['blocked'] === 'true' || product['enterTariff'] === 'false' ? 'col-12 col-md-6 col-lg-3 hidden' : 'col-12 col-md-6 col-lg-3'">
								<div class="card product-card">
									<img :src="getProductSrcImage(product)" class="card-img-top product-image" :onerror="`this.src='${cdnRoot}/catalogo/recursos/imagen-no-disponible.png'`">
									<div class="card-body pb-0">
										<h5 class="card-title" style="color: #000000;">{{ product['presentation'] }}</h5>
										<p class="card-text">{{ product['productCode'] }}</p>
									</div>
                                </div>
							</div>
						</div>
					</div>
				</div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-principal outlined" href="#counselingFormModal" data-bs-toggle="modal" style="margin: 0;">Quiero asesoramiento</button>
                </div>
      </div>
    </div>
    <!-- Spinner -->
        <!-- DIV -->
        <div class="loading-container" id="spinner" style="display:none;">
            <div class="spinner-border text-info" role="status">
                <span class="visually-hidden">{{ $t('common.loading') }}</span>
            </div>
        </div>   
  </div>
</template>
 
<script>
import BaseComponent from "./common/BaseComponent.js";
import api from "../api/nitida.js";

export default {
  name: 'ProductDetail',
	props: ['item', 'relatedItems', 'subfamily', 'subfamilyType'],
	mixins: [BaseComponent],    
	data() {
   	return {
	 		dam_hostname: process.env.VUE_APP_MGNL_DAM_RAW,
   	}
 	},
	computed: {
		staticRoot() {
            return process.env.VUE_APP_MGNL_STATIC;
        },
	},     
	mounted() {
		$('#spinner').hide(); 
	},
	methods: {
		downloadDocument(document) {
			if (!document) {
				return;
			}
			$('#spinner').show();   
			api.downloadDocument(document, () => {
				$('#spinner').hide();
			},
			(error) => {
				$('#spinner').hide();
			});
		},
		getBackgroundImage() {
			if (!this.item) {
				return '';
			}
            return `${this.cdnRoot}/imagenes/${this.item['productCode']}.jpg`;
		},		
		getProductSrcImage(product) {
			const src = `${this.cdnRoot}/imagenes/${product['productCode']}.jpg`;
			console.log(`*** getProductImage() called! [ src: '${src}' ]`);
			return src;
		},
		getProductTechniqueFileUrl() {
			if (!this.item) {
				return '';
			}
            return `${process.env.VUE_APP_STORAGE_GOOGLE_APIS_CATALOG}catalogo/ficha-tecnica/${this.item['productCode'].substr(0,9)}.pdf`;
		},        
		getProductSecurityFileUrl() {
			if (!this.item) {
				return '';
			}
            return `${process.env.VUE_APP_STORAGE_GOOGLE_APIS_CATALOG}catalogo/ficha-seguridad/${this.item['productCode'].substr(0,9)}.pdf`;
		},
		getProductBiocidaRegisterNumberUrl() {
			if (!this.item) {
				return '';
			}
            return `${process.env.VUE_APP_STORAGE_GOOGLE_APIS_CATALOG}catalogo/registro-biocida/${this.item['productCode'].substr(0,9)}.pdf`;
		},
		getProductEcolabelRegisterNumberUrl() {
			if (!this.item) {
				return '';
			}
            return `${process.env.VUE_APP_STORAGE_GOOGLE_APIS_CATALOG}catalogo/registro-ecolabel/${this.item['productCode'].substr(0,9)}.pdf`;
		},                               
	} 
}
</script>

<style>
/*** Modal ProductDetail ***/
.productDetail .modal-header {
    border-bottom: none;
    padding: 1rem 1rem 0.5rem;
}
.productDetail .modal-header h4 {
    color: #343A40;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1;
}
.date-text {
    color: #009CBD;
    font-size: 0.75rem;
}
.ImageNewsDetail {
    width: 100%; 
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
    border-radius: 5px;
}
.contentNewsDetail {
    color: #343A40;
    font-size: 0.875rem;
}
.modal-footer .btn-flat {
    text-transform: uppercase;
    font-size: 0.938rem;
    color: #000000;
    background-color: transparent;
    border: none;
}
.spinner {
	width: 36px;
	height: 36px;
	border: 5px solid rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	border-left-color: #009CBD;
	animation: spin 1s ease infinite;
}
.hidden {
    display: none;
}
</style>
