import Basic from './pages/Basic';
import Module from './pages/Module';
import Image from './components/Image';
import Headline from './components/Headline';
import DateToday from './components/DateToday';
import Paragraph from './components/Paragraph';
import Expander from './components/Expander';
import Texto from './components/Texto';
import FeaturedNews from './components/FeaturedNews';
import NewsList from './components/NewsList';
import NewsDetail from './components/NewsDetail';
import AreasList from './components/AreasList';
import FeaturedNewsCarousel from './components/FeaturedNewsCarousel';
import ToolsList from './components/ToolsList';
import ToolsItem from './components/ToolsItem';
import ShortcutList from './components/ShortcutList';
import ShortcutItem from './components/ShortcutItem';
import BusinessAreaNavigation from './components/BusinessAreaNavigation';
import BusinessAreaProductsList from './components/BusinessAreaProductsList';
import IncidencesMain from './components/IncidencesMain';
import IncidencesDetail from './components/IncidencesDetail';
import IncidencesNew from './components/IncidencesNew';
import CounselingForm from './components/CounselingForm';
import DocumentationMain from './components/DocumentationMain';

const config = {
  componentMappings: {
    // Pages
    'nitida-lm:pages/basic': Basic,
    'nitida-lm:pages/module': Module,

    // Components
    'nitida-lm:components/headline': Headline,
    'nitida-lm:components/date-today': DateToday,    
    'nitida-lm:components/image': Image,
    'nitida-lm:components/paragraph': Paragraph,
    'nitida-lm:components/expander': Expander,
	'nitida-lm:components/texto': Texto,
	'nitida-lm:components/featured-news': FeaturedNews,
    'nitida-lm:components/featured-news-carousel': FeaturedNewsCarousel,
    'nitida-lm:components/tools-list': ToolsList,   
    'nitida-lm:components/tools-listItem': ToolsItem, 
    'nitida-lm:components/shortcut-list': ShortcutList,   
    'nitida-lm:components/shortcut-listItem': ShortcutItem,         
    'nitida-lm:components/news-list': NewsList,
    'nitida-lm:components/news-detail': NewsDetail,    
    'nitida-lm:components/areas-list': AreasList, 
    'nitida-lm:components/business-area-navigation': BusinessAreaNavigation,    
    'nitida-lm:components/business-area-products-list': BusinessAreaProductsList,        
    'nitida-lm:components/incidences-main': IncidencesMain,   
    'nitida-lm:components/incidences-detail': IncidencesDetail,      
    'nitida-lm:components/incidences-new': IncidencesNew,  
    'nitida-lm:components/counseling-form': CounselingForm,      
    'nitida-lm:components/documentation-main': DocumentationMain,       

  },
};

export default config;
