<template>
  <ul class="ToolsList" ref = "itemListParent">
    <h2 class="Headline">{{  title  }}</h2>      
    <EditableArea
      v-if="items"
	  ref = "itemListArea"
      v-bind:content="items"
      v-bind:parentTemplateId="metadata['mgnl:template']"
      class="toolslist-container"
    />
  </ul>
</template>

<script>
import { EditableArea } from '@magnolia/vue-editor';
import { EditorContextHelper } from '@magnolia/vue-editor';
import Vue from "vue"

export default {
  name: "ToolsList",
  components: {
    EditableArea
  },
  props: ["items", "metadata", "title"],
  mounted() {
		if(!this.$refs.itemListArea) return;
		if (EditorContextHelper.inIframe() || EditorContextHelper.inEditor() || EditorContextHelper.inEditorPreview()) return;
		const me = this;
		setTimeout(function() {
			me.$refs.itemListParent.style.display = (me.$refs.itemListArea.$el.childElementCount==0)?"none":"block";
		},300);
	},
};
</script>

<style>
.toolslist-container {
     display: flex; 
     /*justify-content: center;*/
}

</style>
